import { Box } from '@chakra-ui/react';
import { useParticipantsGridMode } from 'state/ParticipantsGridMode';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const { gridMode } = useParticipantsGridMode();
  const mode3 = gridMode === 'mode 3';

  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <>
      {mode3 ? null : (
        <Box pt={isRemoteScreenSharing ? '70px' : '0'} maxH={{ base: 'calc(100vh - 90px)', xl: 'calc(100vh - 100px)' }}>
          <MainParticipantInfo participant={mainParticipant}>
            <ParticipantTracks
              participant={mainParticipant}
              enableScreenShare={mainParticipant !== localParticipant}
              videoPriority={videoPriority}
              videoOnly
              isLocalParticipant={mainParticipant === localParticipant}
            />
          </MainParticipantInfo>
        </Box>
      )}
    </>
  );
}
