import { PropsWithChildren } from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ModalContentCustom } from 'components/ModalContentCustom';
import Video from 'twilio-video';
import { version as appVersion } from '../../../package.json';
import { useAppState } from '../../state';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function AboutDialog({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  const { roomType } = useAppState();
  return (
    <ModalContentCustom isOpen={open} onClose={onClose} minW="33vw">
      <Box p={{ base: '15px 30px 15px 30px', md: '20px 40px 20px 40px' }} w="100%" fontSize="16">
        <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold" mb="10px">
          About
        </Text>

        <Text>Browser supported: {String(Video.isSupported)}</Text>
        <Text>SDK Version: {Video.version}</Text>
        <Text>App Version: {appVersion}</Text>
        <Text>Deployed Tag: {process.env.REACT_APP_GIT_TAG || 'N/A'}</Text>
        <Text>Deployed Commit Hash: {process.env.REACT_APP_GIT_COMMIT || 'N/A'}</Text>
        {roomType && <Text>Room Type: {roomType}</Text>}
        <Flex justifyContent="flex-end">
          <Button size="custom" ml="auto" variant="branded" onClick={onClose}>
            OK
          </Button>
        </Flex>
      </Box>
    </ModalContentCustom>
  );
}

export default AboutDialog;
