import { ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import AboutDialog from 'components/AboutDialog/AboutDialog';
import SettingsIcon from 'icons/SettingsIcon';
import { StyledIconButton } from './StyledIconButton';

export function SettingsMenuButton() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box display={{ base: 'none', xl: 'flex' }}>
      <Menu>
        <MenuButton>
          <Flex alignItems="center">
            <Stack alignItems="center">
              <StyledIconButton aria-label="Settings Button" zIndex="100" icon={<SettingsIcon fill="white" />} />
              <Text textAlign="center">Settings</Text>
            </Stack>

            <Box
              p="0px 4px"
              ml="8px"
              borderRadius="5"
              bg="#252525"
              _active={{ bg: 'rgba(255,255,255, 0.2)' }}
              _hover={{ bg: 'rgba(255,255,255, 0.2)' }}
            >
              <ChevronUpIcon w="15px" h="15px" />
            </Box>
          </Flex>
        </MenuButton>
        <MenuList
          overflow="hidden"
          border="1px solid rgba(255,255,255, 0.1)"
          bg="#252525"
          borderRadius="10"
          zIndex="1000"
          py="0"
          color="white"
        >
          <MenuItem
            _focus={{}}
            borderBottom="1px solid rgba(255,255,255, 0.1)"
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={() => {
              VideoRoomMonitor.toggleMonitor();
            }}
          >
            Room Monitor
          </MenuItem>

          <MenuItem _focus={{}} _hover={{ bg: 'rgba(255,255,255, 0.1)' }} onClick={onOpen}>
            About
          </MenuItem>
        </MenuList>
      </Menu>
      <AboutDialog open={isOpen} onClose={onClose} />
    </Box>
  );
}
