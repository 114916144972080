import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import AudioLevelIndicator from 'components/AudioLevelIndicator/AudioLevelIndicator';
import DeviceSelectionDialog from 'components/DeviceSelectionDialog/DeviceSelectionDialog';
import useDevices from 'hooks/useDevices/useDevices';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack';
import { useState } from 'react';
import { useAppState } from 'state';
import { LocalAudioTrack } from 'twilio-video';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { StyledIconButton } from './StyledIconButton';
import { SubMenu } from './SubMenu';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { audioInputDevices, audioOutputDevices } = useDevices();
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;
  const [openMenu, setOpenMenu] = useState('');
  const { activeSinkId, setActiveSinkId } = useAppState();
  const size = useBreakpointValue({ base: '22px', lg: '30px' });
  const isMobile = useBreakpointValue({ base: true, xl: false });

  const closeMicrophoneMenu = () => {
    setOpenMenu(state => {
      if (state === 'microphone') return '';
      return 'microphone';
    });
  };

  const closeSpeakerMenu = () => {
    setOpenMenu(state => {
      if (state === 'speaker') return '';
      return 'speaker';
    });
  };

  function selectAudioDevice(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <>
      <ChakraMenu onClose={() => setOpenMenu('')}>
        <Flex alignItems="center">
          <Stack alignItems="center">
            <StyledIconButton
              aria-label="Audio Buttom"
              onClick={toggleAudioEnabled}
              icon={<AudioLevelIndicator height={size} width={size} audioTrack={localAudioTrack} />}
              data-cy-audio-toggle
            />

            <Text fontSize={{ base: '10', lg: '16' }} textAlign="center">
              {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}
            </Text>
          </Stack>
          {isMobile ? null : (
            <MenuButton display={{ base: 'none', xl: 'flex' }}>
              <Box
                p="0px 4px"
                ml="8px"
                borderRadius="5"
                bg="#252525"
                _active={{ bg: 'rgba(255,255,255, 0.2)' }}
                _hover={{ bg: 'rgba(255,255,255, 0.2)' }}
              >
                <ChevronUpIcon w="15px" h="15px" />
              </Box>
            </MenuButton>
          )}
        </Flex>
        <MenuList
          pos="relative"
          overflow="hidden"
          border="1px solid rgba(255,255,255, 0.1)"
          bg="#252525"
          borderRadius="10"
          zIndex="1000"
          py="0"
          color="white"
        >
          <SubMenu
            title="Select a Microphone"
            activeOption={localAudioInputDeviceId}
            isOpen={openMenu === 'microphone'}
            onClose={closeMicrophoneMenu}
            handleChange={selectAudioDevice}
            optionsArray={audioInputDevices}
          />

          <SubMenu
            title="Select a Speaker"
            activeOption={activeSinkId}
            isOpen={openMenu === 'speaker'}
            onClose={closeSpeakerMenu}
            handleChange={setActiveSinkId}
            optionsArray={audioOutputDevices}
          />

          <MenuItem _hover={{ bg: 'rgba(255,255,255, 0.1)' }} onClick={onOpen}>
            Audio settings...
          </MenuItem>
        </MenuList>
      </ChakraMenu>
      <DeviceSelectionDialog audioSettings={true} videoSettings={false} open={isOpen} onClose={onClose} />
    </>
  );
}
