import { Box, Flex } from '@chakra-ui/react';
import { FormControl, Typography } from '@material-ui/core';
import { InputOutputList } from 'components/InputOutputList';
import { LocalAudioTrack } from 'twilio-video';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from 'hooks/useDevices/useDevices';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <Flex alignItems="center" w="100%">
      {audioInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <InputOutputList
            activeValue={localAudioInputDeviceId}
            devicesArray={audioInputDevices}
            changeDeviceFunction={replaceTrack}
          />
        </FormControl>
      ) : (
        <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
      )}

      <Box ml="10px">
        <AudioLevelIndicator audioTrack={localAudioTrack} fill="black" />
      </Box>
    </Flex>
  );
}
