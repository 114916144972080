import { Icon, IconProps } from '@chakra-ui/react';

export function ScreenShareFilledIcon({
  size = '24px',
  isSharing = false,
  ...rest
}: IconProps & { size?: string; isSharing?: boolean }) {
  return (
    <Icon width={size} height={size} viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M19 0H5C3.6744 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.6744 0 5L0 11C0.00158786 12.3256 0.528882 13.5964 1.46622 14.5338C2.40356 15.4711 3.6744 15.9984 5 16H11V17H8C7.73478 17 7.48043 17.1054 7.29289 17.2929C7.10536 17.4804 7 17.7348 7 18C7 18.2652 7.10536 18.5196 7.29289 18.7071C7.48043 18.8946 7.73478 19 8 19H16C16.2652 19 16.5196 18.8946 16.7071 18.7071C16.8946 18.5196 17 18.2652 17 18C17 17.7348 16.8946 17.4804 16.7071 17.2929C16.5196 17.1054 16.2652 17 16 17H13V16H19C20.3256 15.9984 21.5964 15.4711 22.5338 14.5338C23.4711 13.5964 23.9984 12.3256 24 11V5C23.9984 3.6744 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0Z"
        fill={isSharing ? '#F6BA55' : 'white'}
      />
      <path
        d="M12.975 12.286V5.143L15.325 7.493C15.4601 7.6196 15.6391 7.68884 15.8242 7.68614C16.0094 7.68345 16.1862 7.60903 16.3176 7.47855C16.449 7.34807 16.5247 7.17171 16.5286 6.98658C16.5326 6.80145 16.4646 6.622 16.339 6.486L13.268 3.421C13.0012 3.15295 12.6391 3.00157 12.261 3C11.8852 3.00341 11.526 3.15466 11.261 3.421L8.18996 6.485C8.0643 6.621 7.9963 6.80045 8.00028 6.98558C8.00426 7.17071 8.0799 7.34707 8.21129 7.47755C8.34268 7.60803 8.51956 7.68245 8.70471 7.68514C8.88986 7.68784 9.06883 7.6186 9.20396 7.492L11.546 5.143V12.286C11.546 12.4755 11.6212 12.6572 11.7552 12.7912C11.8892 12.9252 12.071 13.0005 12.2605 13.0005C12.45 13.0005 12.6317 12.9252 12.7657 12.7912C12.8997 12.6572 12.975 12.4755 12.975 12.286V12.286Z"
        fill="#313131"
      >
        {isSharing ? (
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0;0 -2;0 0"
            dur="2s"
            repeatCount="indefinite"
          />
        ) : null}
      </path>
    </Icon>
  );
}
