import { Icon, IconProps } from '@chakra-ui/react';

export function BarsQualityLevel({ size = '20px', level }: IconProps & { size?: string; level: number }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15.75 15">
      <g transform="translate(-171 -950)">
        <rect
          width="3.75"
          height="15"
          rx="1.875"
          transform="translate(183 950)"
          fill={level > 4 ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        />
        <rect
          width="3.75"
          height="11.25"
          rx="1.875"
          transform="translate(177 953.75)"
          fill={level > 2 ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        />
        <rect
          width="3.75"
          height="7.5"
          rx="1.875"
          transform="translate(171 957.5)"
          fill={level > 0 ? 'white' : 'rgba(255, 255, 255, 0.2)'}
        />
      </g>
    </Icon>
  );
}
