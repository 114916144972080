import { IconButton } from '@chakra-ui/react';
import { SELECTED_VIDEO_INPUT_KEY, DEFAULT_VIDEO_CONSTRAINTS } from '../../constants';
import useDevices from 'hooks/useDevices/useDevices';
import { CameraIcon } from 'icons/CameraIcon';
import { useState } from 'react';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';

export function ButtonToggleChangeCamera() {
  const { videoInputDevices } = useDevices();
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  function handleChangeCamera() {
    const active = videoInputDevices.findIndex(current => current.deviceId === localVideoInputDeviceId);
    if (videoInputDevices[active + 1]) {
      replaceTrack(videoInputDevices[active + 1].deviceId);
    } else {
      replaceTrack(videoInputDevices[0].deviceId);
    }
  }

  return (
    <IconButton
      onClick={() => handleChangeCamera()}
      aria-label="Change Layout mode 1"
      bg="rgba(255,255,255, 0.1)"
      borderRadius="15"
      h="42px"
      w="42px"
      mr="10px"
      _hover={{}}
      _active={{ bg: 'rgba(255,255,255, 0.2)' }}
      _focus={{}}
      icon={<CameraIcon width="21px" height="21px" />}
    />
  );
}
