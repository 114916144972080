import { Icon, IconProps } from '@chakra-ui/react';

export function LoadingIcon({ size = '30px', ...rest }: IconProps & { size?: string }) {
  return (
    <Icon id="loading" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 30" {...rest}>
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="-2.836" y2="3.21" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffe08b" />
          <stop offset="1" stopColor="#dea94e" />
        </linearGradient>
      </defs>
      <animateTransform attributeName="transform" type="rotate" from="0" to="360" dur="5s" repeatCount="indefinite" />
      <g>
        <path
          d="M225.452,5.565A1.426,1.426,0,0,1,224,4.113V1.452a1.452,1.452,0,0,1,2.9,0V4.113A1.456,1.456,0,0,1,225.452,5.565Z"
          transform="translate(-210.451)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M225.452,409.565A1.426,1.426,0,0,1,224,408.113v-2.661a1.452,1.452,0,0,1,2.9,0v2.661A1.426,1.426,0,0,1,225.452,409.565Z"
          transform="translate(-210.451 -379.564)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M114.895,35.714a1.458,1.458,0,0,1-1.258-.726l-1.306-2.274a1.452,1.452,0,1,1,2.516-1.452l1.306,2.274a1.481,1.481,0,0,1-.532,1.984A1.794,1.794,0,0,1,114.895,35.714Z"
          transform="translate(-105.363 -28.698)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M317.295,385.314a1.458,1.458,0,0,1-1.258-.726l-1.306-2.274a1.452,1.452,0,1,1,2.516-1.452l1.306,2.274a1.481,1.481,0,0,1-.532,1.984A1.365,1.365,0,0,1,317.295,385.314Z"
          transform="translate(-295.521 -357.152)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M34.263,116.346a1.365,1.365,0,0,1-.726-.194l-2.274-1.306a1.452,1.452,0,1,1,1.452-2.516l2.274,1.306a1.481,1.481,0,0,1,.532,1.984A1.458,1.458,0,0,1,34.263,116.346Z"
          transform="translate(-28.697 -105.362)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M383.911,318.746a1.366,1.366,0,0,1-.726-.194l-2.323-1.306a1.452,1.452,0,1,1,1.452-2.516l2.274,1.306a1.481,1.481,0,0,1,.532,1.984A1.331,1.331,0,0,1,383.911,318.746Z"
          transform="translate(-357.152 -295.521)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M4.113,226.9H1.452a1.452,1.452,0,0,1,0-2.9H4.113a1.426,1.426,0,0,1,1.452,1.452A1.456,1.456,0,0,1,4.113,226.9Z"
          transform="translate(0 -210.451)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M408.113,226.9h-2.661a1.452,1.452,0,0,1,0-2.9h2.661a1.452,1.452,0,0,1,0,2.9Z"
          transform="translate(-379.564 -210.451)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M31.188,318.746a1.458,1.458,0,0,1-1.258-.726,1.481,1.481,0,0,1,.532-1.984l2.274-1.306a1.452,1.452,0,1,1,1.452,2.516l-2.274,1.306A1.166,1.166,0,0,1,31.188,318.746Z"
          transform="translate(-27.947 -295.521)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M381.588,116.346a1.458,1.458,0,0,1-1.258-.726,1.481,1.481,0,0,1,.532-1.984l2.274-1.306a1.452,1.452,0,0,1,1.452,2.516l-2.274,1.306A1.365,1.365,0,0,1,381.588,116.346Z"
          transform="translate(-357.152 -105.362)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M113.588,385.314a1.366,1.366,0,0,1-.726-.194,1.481,1.481,0,0,1-.532-1.984l1.306-2.274a1.452,1.452,0,1,1,2.516,1.452l-1.306,2.274A1.458,1.458,0,0,1,113.588,385.314Z"
          transform="translate(-105.363 -357.152)"
          fill="url(#linear-gradient)"
        />
        <path
          d="M315.988,35.714a1.365,1.365,0,0,1-.726-.194,1.481,1.481,0,0,1-.532-1.984l1.306-2.274a1.452,1.452,0,0,1,2.516,1.452l-1.306,2.274A1.572,1.572,0,0,1,315.988,35.714Z"
          transform="translate(-295.521 -28.698)"
          fill="url(#linear-gradient)"
        />
      </g>
    </Icon>
  );
}
