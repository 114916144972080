import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { slimScroll } from 'components/Scroll';
import { SmileIcon } from 'icons/SmileIcon';
import { emojis } from './emojis';

export function MenuEmojis({
  messageBody,
  setMessageBody,
}: {
  messageBody: string;
  setMessageBody: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Menu>
      <MenuButton as={Box} _active={{ bg: '#F2F2F2' }} borderRadius="5" _hover={{ bg: '#F2F2F2' }}>
        <Flex justifyContent="center" w="25px" h="25px" alignItems="center">
          <SmileIcon />
        </Flex>
      </MenuButton>
      <MenuList
        bg="#F7FCFF"
        borderRadius="10"
        boxShadow="0 0 1em rgba(0,0,0, 0.14)"
        maxH="350px"
        overflow="auto"
        maxW="300px"
        css={slimScroll}
        display="flex"
        flexWrap="wrap"
      >
        {emojis.map((item, index) => (
          <MenuItem
            w="auto"
            as={Box}
            key={`emoji-${index}`}
            p="8px"
            borderRadius="5"
            transition="0.3s"
            _hover={{ bg: '#F2F2F2' }}
            onClick={() => {
              setMessageBody(messageBody + item);
            }}
            closeOnSelect={true}
            cursor="pointer"
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
