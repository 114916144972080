import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function SettingsIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20.066"
      height="19"
      viewBox="0 0 20.066 19"
      fill="#002b49"
      {...rest}
    >
      <path
        d="M19.869,8.517A2.622,2.622,0,0,1,17.8,4.972a1.231,1.231,0,0,0-.368-1.385,9.866,9.866,0,0,0-2.943-1.706,1.237,1.237,0,0,0-1.4.388,2.615,2.615,0,0,1-4.12,0,1.237,1.237,0,0,0-1.4-.388A9.913,9.913,0,0,0,4.833,3.42a1.244,1.244,0,0,0-.388,1.425A2.609,2.609,0,0,1,2.271,8.43a1.237,1.237,0,0,0-1.084,1A9.458,9.458,0,0,0,1,11.3a9.578,9.578,0,0,0,.127,1.572,1.237,1.237,0,0,0,1.09,1.037,2.609,2.609,0,0,1,2.127,3.686,1.217,1.217,0,0,0,.341,1.458A9.939,9.939,0,0,0,7.6,20.731a1.338,1.338,0,0,0,.421.074,1.231,1.231,0,0,0,1-.522,2.589,2.589,0,0,1,2.14-1.124,2.622,2.622,0,0,1,2.1,1.057,1.231,1.231,0,0,0,1.445.408,10.033,10.033,0,0,0,2.676-1.6,1.237,1.237,0,0,0,.361-1.411,2.609,2.609,0,0,1,2.094-3.605,1.237,1.237,0,0,0,1.05-1.017,9.7,9.7,0,0,0,.174-1.692A9.6,9.6,0,0,0,20.9,9.511a1.224,1.224,0,0,0-1.03-.995ZM14.377,11.3a3.344,3.344,0,1,1-3.344-3.344A3.344,3.344,0,0,1,14.377,11.3Z"
        transform="translate(-1 -1.804)"
      />
    </Icon>
  );
}
