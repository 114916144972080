import { Icon, IconProps } from '@chakra-ui/react';

export function InviteIcon({ ...rest }: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" {...rest}>
      <path
        d="M20,25H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H20a5.006,5.006,0,0,1,5,5V20A5.006,5.006,0,0,1,20,25ZM8.833,12.166a.833.833,0,0,0,0,1.667h3.334v3.334a.833.833,0,1,0,1.667,0V13.833h3.333a.833.833,0,1,0,0-1.667H13.834V8.833a.833.833,0,0,0-1.667,0v3.333Z"
        fill="#f6ba55"
      />
    </Icon>
  );
}
