import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  ModalContentProps,
} from '@chakra-ui/react';

export function ModalContentCustom({
  isOpen,
  onClose = () => {},
  withoutCloseButton = false,
  children,
  backdropFilter = undefined,
  ...rest
}: ModalContentProps & {
  isOpen: boolean;
  onClose?: () => void;
  withoutCloseButton?: boolean;
  backdropFilter?: string | undefined;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter={backdropFilter} />
      <ModalContent minW="90%" mt="auto" mb="auto" mx="0" bg="white" borderRadius="20" {...rest}>
        <ModalHeader p="0">
          {withoutCloseButton ? null : (
            <ModalCloseButton w="15px" h="15px" _focus={{}} color="black" top="15px" right="15px" />
          )}
        </ModalHeader>
        <ModalBody p="0">
          <Flex>{children}</Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
