import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import {
  Button as ChakraButton,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useAppState } from '../../../state';
import LogoSVG from 'images/brand/logo.svg';
import { Loading } from '../../Loading';
import { CopyIcon } from 'icons/CopyIcon';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import useRoleContext from 'hooks/useRoleContext/useRoleContext';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    marginTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  createButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  hasRoomName: boolean;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  hasRoomName,
  setName,
  setRoomName,
  handleSubmit,
}: RoomNameScreenProps) {
  function handleRoomNameChange(event: ChangeEvent<HTMLInputElement>) {
    setRoomName(event.target.value);
  }

  const location = useLocation();

  const { setRole } = useRoleContext();

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const query = new URLSearchParams(location.search);
        const userToken = query.get('userToken');
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/room/${roomName}/${userToken}`);
        setRole(data.role.role);
        const formattedName =
          data.role.role === 'patient'
            ? data.user?.personal?.name
            : `${data.user?.personal?.name}, ${data.user?.doctor?.title}`;
        setName(formattedName);
      } catch (error) {
        console.log(error);
      }
    };
    if (roomName) getData();
  }, [roomName, setRole, setName, location]);

  return (
    <>
      <Box>
        <Image mx="auto" h={{ base: '60px', md: '70px' }} src={LogoSVG} mb="40px" />
        <Box
          p={{ base: '30px', md: '40px' }}
          w={{ base: '95vw', md: '430px' }}
          bg="white"
          borderRadius="10px"
          boxShadow="0px 20px 50px rgba(0,0,0,.05)"
        >
          <Heading mb="10px" lineHeight={'1em'} fontSize={{ base: '25', md: '30' }}>
            Join{' '}
            <Text as="span" color="brand.500">
              Appointment
            </Text>
          </Heading>
          <Text fontSize="16px" mb="15px">{`Appointment ID: #${roomName}`}</Text>
          {name ? (
            <form onSubmit={handleSubmit}>
              <FormLabel mb="32px">
                Hello, <strong>{name}</strong>
              </FormLabel>
              {/* <FormControl mb="24px">
                <FormLabel mb="16px">How would you like to be called?</FormLabel>
                <Input
                  size="lg"
                  placeholder="Your Name"
                  variant="outline"
                  border="1px solid #babacc"
                  _focus={{ border: '1px solid #F6BA55' }}
                  type="text"
                  w="100%"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
              </FormControl> */}

              {/* <Text my="10px">Share this consult link to invite others:</Text>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                py="10px"
                px="20px"
                bg="#F4F4F7"
                mb="15px"
                borderRadius="10px"
              >
                <Text id="roomLink">
                  {hasRoomName ? window.location.href : window.location.origin + '/room/' + roomName}
                </Text>
                <Box
                  _active={{ bg: '#ddd' }}
                  p="5px"
                  borderRadius="5px"
                  transition="0.3s"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.origin + '/room/' + roomName);
                  }}
                >
                  <CopyIcon />
                </Box>
              </Flex> */}
              <Flex justifyContent="flex-end" alignItems="center">
                {/* <Box mr="15px">
                    <Loading isLoading={false} />
                  </Box> */}
                <ChakraButton
                  variant="branded"
                  _focus={{}}
                  _hover={{ bg: '#f2b246' }}
                  size="custom"
                  disabled={!name.length}
                  type="submit"
                  w="100%"
                  borderRadius="10px"
                >
                  Join Appointment
                </ChakraButton>
              </Flex>
            </form>
          ) : (
            <Flex my="40px" justifyContent={'center'}>
              <Spinner />
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
}
