import { Stack, StackProps, Text } from '@chakra-ui/react';
import useIsRecording from 'hooks/useIsRecording/useIsRecording';
import useRoleContext from 'hooks/useRoleContext/useRoleContext';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { RecordIcon } from 'icons/RecordIcon';
import { useEffect } from 'react';
import { useAppState } from 'state';
import { StyledIconButton } from './StyledIconButton';

export function ToggleRecordButton({ ...rest }: StackProps) {
  const { isFetching, updateRecordingRules, roomType } = useAppState();
  const isRecording = useIsRecording();
  const { room } = useVideoContext();

  useEffect(() => {
    if (!isRecording) {
      updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
    }
  }, [isRecording, room, updateRecordingRules]);

  return (
    <>
      {roomType !== 'peer-to-peer' && roomType !== 'go' && (
        <Stack alignItems="center" {...rest} d="none">
          <StyledIconButton
            aria-label="Record Button"
            disabled={isFetching}
            icon={
              <RecordIcon
                isRecording={isRecording}
                width={{ base: '18px', lg: '21.122px' }}
                height={{ base: '18px', lg: '21.122px' }}
              />
            }
            onClick={() => {
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          />

          <Text textAlign="center" fontSize={{ base: '10', lg: '16' }}>
            {isRecording ? 'Recording' : 'Record'}
          </Text>
        </Stack>
      )}
    </>
  );
}
