import { Icon, IconProps } from '@chakra-ui/react';

export function SendFileIcon({ ...rest }: IconProps) {
  return (
    <Icon width="13px" height="15px" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M12.5 6.55302V11.875C12.4989 12.7035 12.1694 13.4977 11.5835 14.0836C10.9977 14.6694 10.2035 14.999 9.375 15H3.125C2.29652 14.999 1.50228 14.6694 0.91646 14.0836C0.330638 13.4977 0.00105842 12.7035 0 11.875V3.12502C0.00105842 2.29654 0.330638 1.5023 0.91646 0.916476C1.50228 0.330654 2.29652 0.00107386 3.125 1.54362e-05H5.947C6.52178 -0.00151592 7.09117 0.110907 7.62224 0.330783C8.15331 0.55066 8.63552 0.873628 9.041 1.28102L11.218 3.46002C11.6257 3.86513 11.9489 4.34711 12.169 4.87804C12.389 5.40897 12.5015 5.97829 12.5 6.55302V6.55302ZM8.157 2.16502C7.96029 1.9743 7.73941 1.81024 7.5 1.67702V4.37702C7.50053 4.54243 7.56661 4.70089 7.68376 4.81766C7.80092 4.93444 7.95959 5.00002 8.125 5.00002H10.825C10.6919 4.76085 10.5278 4.54028 10.337 4.34402L8.157 2.16502ZM11.25 6.55302C11.25 6.45302 11.23 6.35302 11.221 6.25302H8.121C7.62389 6.25196 7.14755 6.05353 6.7967 5.70137C6.44585 5.3492 6.2492 4.87212 6.25 4.37502V1.27502C6.15 1.26602 6.05 1.24602 5.95 1.24602H3.125C2.87843 1.24601 2.63429 1.29465 2.40654 1.38912C2.1788 1.4836 1.97192 1.62207 1.79776 1.7966C1.6236 1.97114 1.48557 2.17831 1.39158 2.40626C1.29758 2.6342 1.24947 2.87845 1.25 3.12502V11.875C1.25 12.3723 1.44754 12.8492 1.79917 13.2008C2.15081 13.5525 2.62772 13.75 3.125 13.75H9.375C9.87228 13.75 10.3492 13.5525 10.7008 13.2008C11.0525 12.8492 11.25 12.3723 11.25 11.875V6.55302Z"
        fill="#8C8C8C"
      />
    </Icon>
  );
}
