import { Icon, IconProps } from '@chakra-ui/react';

export function InfoOutlineIcon({ ...rest }: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="16.965" height="16.965" viewBox="0 0 16.965 16.965" {...rest}>
      <path
        d="M8.482,0a8.482,8.482,0,1,0,8.482,8.482A8.482,8.482,0,0,0,8.482,0Zm0,15.551a7.069,7.069,0,1,1,7.069-7.069A7.069,7.069,0,0,1,8.482,15.551Z"
        transform="translate(0 0)"
        fill="#00a3dd"
      />
      <path
        d="M11.414,10h-.707a.707.707,0,1,0,0,1.414h.707v4.241a.707.707,0,0,0,1.414,0V11.414A1.414,1.414,0,0,0,11.414,10Z"
        transform="translate(-2.931 -2.931)"
        fill="#00a3dd"
      />
      <ellipse cx="1.06" cy="1.06" rx="1.06" ry="1.06" transform="translate(7.422 3.534)" fill="#00a3dd" />
    </Icon>
  );
}
