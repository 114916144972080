import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

type LayoutModes = 'mode 1' | 'mode 2' | 'mode 3';

interface ContextType {
  gridMode: LayoutModes;
  setGridMode: React.Dispatch<React.SetStateAction<LayoutModes>>;
}

const participantsGridMode = createContext<undefined | ContextType>(undefined);

export const GridModeProvider = ({ children }: { children: ReactNode }) => {
  const [gridMode, setGridMode] = useState<LayoutModes>('mode 2');

  useEffect(() => {
    if (window) {
      if (window.innerWidth < 768) setGridMode('mode 1');
    }
  }, []);

  return (
    <participantsGridMode.Provider
      value={{
        gridMode,
        setGridMode,
      }}
    >
      {children}
    </participantsGridMode.Provider>
  );
};

export const useParticipantsGridMode = () => {
  const context = useContext(participantsGridMode);
  if (context === undefined) throw new Error('useApplicationContext must be used within a ApplicationContextProvider');
  return context;
};
