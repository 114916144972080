import { Icon, IconProps } from '@chakra-ui/react';

export function CopyIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="21.045px"
      height="25px"
      viewBox="0 0 21.045 25"
      fill="url(#linear-gradient)"
      {...rest}
    >
      <defs>
        <linearGradient id="linear-gradient" x1="1" y1="0.642" x2="0" y2="0.642" gradientUnits="objectBoundingBox">
          <stop offset="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
      <path
        id="copy"
        d="M13.232,25H3.906A3.911,3.911,0,0,1,0,21.094V7.861A3.911,3.911,0,0,1,3.906,3.955h9.326a3.911,3.911,0,0,1,3.906,3.906V21.094A3.911,3.911,0,0,1,13.232,25ZM3.906,5.908A1.955,1.955,0,0,0,1.953,7.861V21.094a1.955,1.955,0,0,0,1.953,1.953h9.326a1.955,1.955,0,0,0,1.953-1.953V7.861a1.955,1.955,0,0,0-1.953-1.953ZM21.045,18.652V3.906A3.911,3.911,0,0,0,17.139,0H6.3a.977.977,0,0,0,0,1.953h10.84a1.955,1.955,0,0,1,1.953,1.953V18.652a.977.977,0,0,0,1.953,0Zm0,0"
      />
    </Icon>
  );
}
