import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Flex, Text } from '@chakra-ui/react';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.425em 0 0.083em',
      fontSize: '12px',
      color: '#606B85',
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({ author, dateCreated, isLocalParticipant }: MessageInfoProps) {
  const classes = useStyles();

  return (
    <Flex
      my="8px"
      w="calc(100% - 35px)"
      flexDir={isLocalParticipant ? 'row-reverse' : 'row'}
      alignItems="center"
      justifyContent={isLocalParticipant ? 'end' : 'flex-start'}
      ml={isLocalParticipant ? '0px' : '35px'}
      mr={isLocalParticipant ? '35px' : '0px'}
    >
      <Text ml={isLocalParticipant ? '10px' : '0px'} fontSize="14" mr={isLocalParticipant ? '0' : '10px'}>
        {isLocalParticipant ? `${author} (You)` : author}
      </Text>
      <Text color="#8C8C8C" fontSize="12">
        {dateCreated}
      </Text>
    </Flex>
  );
}
