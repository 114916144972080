import React from 'react';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import AvatarIcon from '../../icons/AvatarIcon';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import PinIcon from './PinIcon/PinIcon';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ScreenShareFilledIcon } from 'icons/ScreenShareFilledIcon';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import { useParticipantsGridMode } from 'state/ParticipantsGridMode';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));
  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const { gridMode } = useParticipantsGridMode();
  const mode1 = gridMode === 'mode 1';

  return (
    <>
      <Flex
        display={hideParticipant ? 'none' : 'flex'}
        borderRadius="10"
        pos="relative"
        css={{ aspectRatio: '1.7777' }}
        overflow="hidden"
        border="none"
        justifyContent="center"
        alignItems="center"
        bg="#101010"
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        {!isVideoEnabled || isVideoSwitchedOff ? <AvatarIcon /> : null}
        {children}
        <Flex alignItems="center" p="10px 15px 8px 8px" pos="absolute" bg="rgba(255,255,255, 0.1)" bottom="0" left="0">
          <Box mr="8px">
            <AudioLevelIndicator audioTrack={audioTrack} />
          </Box>
          <Text color="white" fontSize="16">
            {participant.identity} {isLocalParticipant && ' (You)'}
          </Text>
          {isScreenShareEnabled && (
            <Box ml="10px">
              <ScreenShareFilledIcon isSharing={!!isScreenShareEnabled} width="20px" />
            </Box>
          )}
          {isSelected && <PinIcon />}
          {isParticipantReconnecting ? <Text ml="8px">Reconnecting...</Text> : null}
        </Flex>
      </Flex>
    </>
  );
}
