import { Flex, Grid } from '@chakra-ui/react';
import useMainParticipant from 'hooks/useMainParticipant/useMainParticipant';
import useParticipants from 'hooks/useParticipants/useParticipants';
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useParticipantsGridMode } from 'state/ParticipantsGridMode';
import Participant from '../Participant/Participant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

export default function ParticipantList() {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();

  const { gridMode } = useParticipantsGridMode();
  const mode1 = gridMode === 'mode 1';
  const mode2 = gridMode === 'mode 2';
  const mode3 = gridMode === 'mode 3';
  const gridModeColumns = () => {
    if (mode3) {
      const numberOfParticipants = participants.length + 1; //+1 count this participant
      switch (true) {
        case numberOfParticipants === 1:
          return 1;
        case numberOfParticipants > 1 && numberOfParticipants < 7:
          return 3;
        case numberOfParticipants > 6 && numberOfParticipants < 17:
          return 5;
        case numberOfParticipants > 16 && numberOfParticipants < 26:
          return 5;
        default:
          return 6;
      }
    } else return 1;
  };

  if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <Flex
      alignItems="start"
      display={mode1 ? 'none' : 'block'}
      overflow="auto"
      px="25px"
      css={{ '::-webkit-scrollbar': { display: 'none' } }}
      pt="70px"
    >
      <Grid
        gap="20px"
        w="100%"
        gridTemplateColumns={{ base: '1fr', lg: `repeat(${gridModeColumns()}, 1fr)` }}
        borderRadius="10"
        pt="20px"
      >
        <Participant participant={localParticipant} isLocalParticipant={true} />
        {participants.map(participant => {
          const isSelected = participant === selectedParticipant;
          const hideParticipant =
            participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              onClick={!mode3 ? () => setSelectedParticipant(participant) : undefined}
              hideParticipant={mode3 ? false : hideParticipant}
            />
          );
        })}
      </Grid>
    </Flex>
  );
}
