import React, { createContext, useState } from 'react';

type RoleContextType = {
  role: string;
  setRole: (val: string) => void;
};

export const RoleContext = createContext<RoleContextType>(null!);

export const RoleProvider: React.FC = ({ children }) => {
  const [role, setRole] = useState('');

  return <RoleContext.Provider value={{ role, setRole }}>{children}</RoleContext.Provider>;
};
