import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { defaultToastStyle } from 'chakra/toastStyle';
import { slimScroll } from 'components/Scroll';
import useParticipants from 'hooks/useParticipants/useParticipants';
import { LoadingIcon } from 'icons/LoadingIcon';
import { SendFileIcon } from 'icons/SendFileIcon';
import { SendIcon } from 'icons/SendIcon';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from '../../../utils';
import { MenuChatConfig } from './MenuChatConfig';
import { MenuEmojis } from './MenuEmojis';

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({ conversation, isChatWindowOpen }: ChatInputProps) {
  const [messageBody, setMessageBody] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const ParticipantList = useParticipants();
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [messageDestination, setMessageDestination] = useState('Everyone');
  const toast = useToast();
  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (fileSendError) {
      toast({ title: fileSendError, status: 'error', ...defaultToastStyle });
    }
  }, [fileSendError]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      conversation.sendMessage(message.trim());
      setMessageBody('');
    }
  };

  const hangleMessageDestination = (entity: string) => {
    setMessageDestination(entity);
    // implementar aqui o destino da mensagem
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      var formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        .sendMessage(formData)
        .catch(e => {
          if (e.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  return (
    <Box bg="#F7FCFF" pb="12px" borderRadius="15" w="100%" border="1px solid #F2F2F2">
      <Textarea
        ref={textInputRef}
        fontSize="14"
        variant="outline"
        borderBottom="1px solid transparent"
        _focus={{}}
        resize="none"
        _hover={{}}
        css={slimScroll}
        placeholder="Type message here..."
        onChange={handleChange}
        onKeyPress={handleReturnKeyPress}
        value={messageBody}
      />
      <Flex justifyContent="space-between" alignItems="center" px="12px" mt="10px">
        <Flex fontSize="14" alignItems="center">
          <Text mr="8px">To:</Text>

          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="rgba(60,217,190, 0.1)"
              _hover={{ bg: 'rgba(60,217,190, 0.2)' }}
              fontSize="14"
              py="8px"
              h="auto"
              _focus={{}}
            >
              {messageDestination}
            </MenuButton>
            {/* all participants list */}
            <MenuList py="0" maxH="350px" overflow="auto" border="none">
              <MenuItem onClick={() => hangleMessageDestination('Everyone')}>Everyone</MenuItem>
              {ParticipantList.map((item, index) => (
                <MenuItem onClick={() => hangleMessageDestination(item.identity)} _hover={{ bg: '#F2F2F2' }}>
                  {item.identity}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <HStack spacing="10px" alignItems="center">
          <Flex
            justifyContent="center"
            w="25px"
            h="25px"
            alignItems="center"
            _hover={{ bg: '#F2F2F2' }}
            borderRadius="5"
            onClick={() => fileInputRef.current?.click()}
            pointerEvents={isSendingFile ? 'none' : undefined}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleSendFile}
              value={''}
              accept={ALLOWED_FILE_TYPES}
            />
            {isSendingFile ? <LoadingIcon size="12px" /> : <SendFileIcon />}
          </Flex>
          <MenuEmojis messageBody={messageBody} setMessageBody={setMessageBody} />
          <MenuChatConfig />
          <Button
            borderRadius="10"
            bg="brand.500"
            _hover={{ bg: '#f3a725' }}
            color="white"
            rightIcon={<SendIcon />}
            disabled={messageBody === ''}
            onClick={() => handleSendMessage(messageBody)}
          >
            Send
          </Button>
        </HStack>
      </Flex>
    </Box>
  );
}
