import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function MicIcon({ isDisabled = false, ...rest }: IconProps & { isDisabled?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 19.451 30.999" {...rest}>
      <g transform="translate(-578 -599)">
        <g transform="translate(3 -79)">
          <g transform="translate(575 678)">
            <path
              d="M19.952,260.711a.895.895,0,0,0-1.789,0,7.936,7.936,0,1,1-15.872,0,.895.895,0,0,0-1.789,0,9.738,9.738,0,0,0,8.831,9.684V272.8H6.012a.895.895,0,1,0,0,1.789h8.43a.895.895,0,0,0,0-1.789h-3.32v-2.409a9.738,9.738,0,0,0,8.831-9.684Zm0,0"
              transform="translate(-0.501 -244.593)"
              fill="#fff"
            />
            <path
              d="M74.443,21.848a5.735,5.735,0,0,0,5.729-5.729V5.729a5.729,5.729,0,0,0-11.457,0v10.39A5.735,5.735,0,0,0,74.443,21.848ZM70.5,5.729a3.939,3.939,0,0,1,7.879,0v10.39a3.939,3.939,0,1,1-7.879,0Zm0,0"
              transform="translate(-64.717 -0.001)"
              fill="#fff"
            />
          </g>
        </g>
        {isDisabled ? (
          <rect width="1.973" height="34.655" rx="0.986" transform="translate(595.328 599) rotate(30)" fill="#e52b51" />
        ) : null}
      </g>
    </Icon>
  );
}
