import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      500: '#F6BA55',
    },
    customGray: {
      500: '#BABACC',
    },
  },
  components: {
    IconButton: {
      variants: {
        footerButton: {
          width: '50px',
          height: '50px',
          bg: 'rgba(255,255,255, 0.1)',
          borderRadius: '12',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '5px',
      },
      variants: {
        branded: {
          bg: 'brand.500',
          color: 'white',
        },
      },
      sizes: {
        custom: {
          fontSize: '16px',
          height: '50px',
          px: '26px',
          fontWeight: '400',
        },
      },
    },
    Input: {
      baseStyle: {
        borderRadius: '10px',
      },
    },
  },
});

export default theme;
