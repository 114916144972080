import { Box, Button as ChakraButton, Flex, Grid, HStack, Image, Stack } from '@chakra-ui/react';
import { ModalContentCustom } from 'components/ModalContentCustom';
import logowhitetext from 'images/brand/logo-white-text.svg';
import mockfootercomplete from 'images/brand/mock-footer-complete.svg';
import mockinvite from 'images/brand/mock-invite.svg';
import mockrecording from 'images/brand/mockrecording.svg';
import useChatContext from 'hooks/useChatContext/useChatContext';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useAppState } from 'state';
import { Steps } from '../PreJoinScreens';
import { ControlsPainel } from './ControlsPainel';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  return (
    <Stack justifyContent="space-between" w="100%" minH="100vh" bg="#000000">
      <Flex px="40px" py="18px" justifyContent="space-between">
        <Image src={logowhitetext} w={{ base: '120px', md: '100px', lg: '142.69px' }} />
        <Image src={mockrecording} display={{ base: 'none', lg: 'block' }} w={{ lg: '354px' }} />
        <Image src={mockinvite} display={{ base: 'none', md: 'block' }} w="311px" />
      </Flex>
      <Flex display={{ base: 'none', lg: 'flex' }} px="40px" py="18px">
        <Image src={mockfootercomplete} w="100%" />
      </Flex>
      <ModalContentCustom
        isOpen={true}
        backdropFilter="none"
        p={{ base: '25px', md: '40px' }}
        minW={{ base: '90vw', lg: '80vw', xl: '55vw', '2xl': '40vw' }}
        maxW={{ base: '95vw', xl: '55vw', '2xl': '40vw' }}
        borderRadius="20px"
        withoutCloseButton
      >
        <Grid w="100%" gap="20px" gridTemplateColumns={{ base: '1fr', md: '4.7fr 5.3fr' }}>
          <Stack justifyContent="space-between" alignItems="start">
            <ControlsPainel roomName={roomName} disableButtons={disableButtons} />
            <SettingsMenu />
          </Stack>
          <Flex flexDir="column" justifyContent="space-between">
            <Box borderRadius="10px" overflow="hidden">
              <LocalVideoPreview identity={name} />
            </Box>
            <HStack spacing="20px" mt={{ base: '20px', md: '25px' }}>
              <ChakraButton
                _hover={{ bg: '#c5c5c5', color: 'white' }}
                color="#fff"
                size="custom"
                bg="#BABABA"
                w="35%"
                borderRadius="10px"
                onClick={() => setStep(Steps.roomNameStep)}
              >
                Cancel
              </ChakraButton>
              <ChakraButton
                variant="branded"
                onClick={handleJoin}
                _focus={{}}
                size="custom"
                _hover={{ bg: '#f3a725' }}
                borderRadius="10px"
                w="calc(65% - 20px)"
                disabled={disableButtons}
              >
                Join Now
              </ChakraButton>
            </HStack>
          </Flex>
        </Grid>
      </ModalContentCustom>
    </Stack>
  );
}
