import { Box } from '@chakra-ui/react';
import { FormControl, Typography } from '@material-ui/core';
import { InputOutputList } from 'components/InputOutputList';
import { useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import useDevices from 'hooks/useDevices/useDevices';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';

export default function VideoInputList() {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <div>
      {videoInputDevices.length > 1 ? (
        <Box w="calc(100% - 34px)">
          <FormControl fullWidth>
            <InputOutputList
              activeValue={localVideoInputDeviceId}
              devicesArray={videoInputDevices}
              changeDeviceFunction={replaceTrack}
            />
          </FormControl>
        </Box>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
        </>
      )}
    </div>
  );
}
