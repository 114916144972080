import {
  Box,
  Button,
  Button as ChakraButton,
  Flex,
  Grid as ChakraGrid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ModalContentCustom } from 'components/ModalContentCustom';
import { useCallback } from 'react';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useAppState } from '../../state';
import { inputLabels, Settings } from '../../state/settings/settingsReducer';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface SelectObject {
  title: string;
  inputLabels: string;
  values: {
    value: string;
    option: string;
  }[];
}

const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

export default function ConnectionOptionsDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { settings, dispatchSetting } = useAppState();
  const roomState = useRoomState();
  const isDisabled = roomState !== 'disconnected';

  const handleChange = useCallback(
    e => {
      dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
    },
    [dispatchSetting]
  );

  const handleClick = useCallback(
    (name, value) => {
      dispatchSetting({ name: name as keyof Settings, value: value as string });
    },
    [dispatchSetting]
  );

  const selects: SelectObject[] = [
    {
      title: 'Dominant Speaker Priority:',
      inputLabels: 'dominantSpeakerPriority',
      values: [
        { value: 'low', option: 'Low' },
        { value: 'standard', option: 'Standard' },
        { value: 'high', option: 'High' },
        { value: 'default', option: 'Default' },
      ],
    },
    {
      title: 'Track Switch Off Mode:',
      inputLabels: 'trackSwitchOffMode',
      values: [
        { value: 'predicted', option: 'Predicted' },
        { value: 'detected', option: 'Detected' },
        { value: 'disabled', option: 'Disabled' },
        { value: 'default', option: 'Server Default' },
      ],
    },
    {
      title: 'Mode:',
      inputLabels: 'bandwidthProfileMode',
      values: [
        { value: 'grid', option: 'Grid' },
        { value: 'collaboration', option: 'Collaboration' },
        { value: 'presentation', option: 'Presentation' },
        { value: 'default', option: 'Server Default' },
      ],
    },
    {
      title: 'Client Track Switch Off Control:',
      inputLabels: 'clientTrackSwitchOffControl',
      values: [
        { value: 'auto', option: 'Auto' },
        { value: 'manual', option: 'Manual' },
        { value: 'default', option: 'Default' },
      ],
    },
    {
      title: 'Content Preferences Mode:',
      inputLabels: 'contentPreferencesMode',
      values: [
        { value: 'auto', option: 'Auto' },
        { value: 'manual', option: 'Manual' },
        { value: 'default', option: 'Default' },
      ],
    },
    {
      title: 'Max Audio Bitrate:',
      inputLabels: 'maxAudioBitrate',
      values: [
        { value: '8000', option: '8000' },
        { value: '16000', option: '16000' },
        { value: '32000', option: '32000' },
        { value: '64000', option: '64000' },
      ],
    },
  ];

  return (
    <>
      <ModalContentCustom
        borderRadius="20"
        isOpen={open}
        onClose={onClose}
        minW="40vw"
        maxW={{ base: '95vw', xl: '40vw' }}
      >
        <Box p={{ base: '25px', md: '40px' }} w="100%">
          <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold" mb="10px">
            Connection
          </Text>
          {isDisabled ? (
            <Text fontSize="18" mb="15px">
              These settings cannot be changed when connected to a room.
            </Text>
          ) : null}
          <ChakraGrid gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="30px" w="100%">
            {selects.map((item, index) => (
              <Flex flexDir="column" justifyContent="space-between" key={index}>
                <Text fontSize={{ base: '15', md: '18' }} mb="10px">
                  {item.title}
                </Text>
                <SelectConnectionOption
                  selectObject={item}
                  //@ts-ignore
                  activeValue={withDefault(settings[item.inputLabels])}
                  isDisabled={isDisabled}
                  handleClick={handleClick}
                />
              </Flex>
            ))}
          </ChakraGrid>
          <Flex mt="20px" justifyContent="flex-end">
            <ChakraButton size="custom" ml="auto" variant="branded" onClick={onClose}>
              Done
            </ChakraButton>
          </Flex>
        </Box>
      </ModalContentCustom>
    </>
  );
}

function SelectConnectionOption({
  activeValue,
  selectObject,
  handleClick,
  isDisabled,
}: {
  activeValue: string | undefined | null;
  selectObject: SelectObject;
  handleClick: (a: string, b: string) => void;
  isDisabled: boolean;
}) {
  const active = selectObject.values.find(a => a.value === activeValue);
  return (
    <Menu>
      <MenuButton
        w="100%"
        bg="transparent"
        borderRadius="10"
        justifyContent="flex-start"
        display="flex"
        border="1px solid #002B49"
        _hover={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
        _active={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
        _focus={{}}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        textAlign="start"
        disabled={isDisabled}
        whiteSpace="normal"
        fontSize={{ base: '15', md: '16' }}
      >
        {active ? active.option : ''}
      </MenuButton>
      <MenuList w="calc((30vw - 40px) - 15px)" py="0" borderRadius="10" overflow="hidden">
        {selectObject.values.map((item, index) => (
          <MenuItem
            key={`${selectObject.title}-${index}`}
            _hover={{ bg: '#c5c5c5', color: 'white' }}
            _active={{ bg: '#c5c5c5', color: 'white' }}
            _focus={{}}
            // @ts-ignore
            onClick={() => handleClick(inputLabels[selectObject.inputLabels], item.value)}
          >
            {item.option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
