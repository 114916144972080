import { Icon, IconProps } from '@chakra-ui/react';

export function DotsIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="17.001"
      height="4.5"
      viewBox="0 0 17.001 4.5"
      stroke="#fff"
      {...rest}
    >
      <g transform="translate(-3.5 -15)">
        <path
          d="M18,17.25a.75.75,0,1,1-.75-.75A.75.75,0,0,1,18,17.25Z"
          transform="translate(-5.25)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          d="M28.5,17.25a.75.75,0,1,1-.75-.75A.75.75,0,0,1,28.5,17.25Z"
          transform="translate(-9.499)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          d="M7.5,17.25a.75.75,0,1,1-.75-.75A.75.75,0,0,1,7.5,17.25Z"
          transform="translate(-1)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </Icon>
  );
}
