import { Box, Flex, Image } from '@chakra-ui/react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import clsx from 'clsx';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { BlurIcon } from 'icons/BlurIcon';
import { NoneIcon } from 'icons/NoneIcon';

export type Thumbnail = 'none' | 'blur' | 'image';

interface BackgroundThumbnailProps {
  thumbnail: Thumbnail;
  imagePath?: string;
  name?: string;
  index?: number;
}

export default function BackgroundThumbnail({ thumbnail, imagePath, name, index }: BackgroundThumbnailProps) {
  const { backgroundSettings, setBackgroundSettings } = useVideoContext();
  const isImage = thumbnail === 'image';
  const thumbnailSelected = isImage
    ? backgroundSettings.index === index && backgroundSettings.type === 'image'
    : backgroundSettings.type === thumbnail;
  const icons = {
    none: NoneIcon,
    blur: BlurIcon,
    image: null,
  };
  const ThumbnailIcon = icons[thumbnail];

  return (
    <Box
      cursor="pointer"
      overflow="hidden"
      borderRadius="10"
      pos="relative"
      border={thumbnailSelected ? '3px solid var(--chakra-colors-brand-500)' : undefined}
      onClick={() =>
        setBackgroundSettings({
          type: thumbnail,
          index: index,
        })
      }
      bgImage={ThumbnailIcon ? undefined : imagePath}
      bgSize="cover"
      css={{ aspectRatio: '1.8' }}
    >
      {ThumbnailIcon ? (
        <Flex justifyContent="center" alignItems="center" h="100%" w="100%">
          <ThumbnailIcon />
        </Flex>
      ) : null}
      <Flex
        justifyContent="center"
        alignItems="center"
        zIndex="1"
        pos="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        bg="rgba(0,0,0, 0.4)"
        color="white"
        opacity="0"
        transition="0.4s"
        _hover={{ opacity: '1' }}
      >
        {name}
      </Flex>
    </Box>
  );
}
