import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';
import EndCallButton from 'components/Buttons/EndCallButton/EndCallButton';
import useIsRecording from 'hooks/useIsRecording/useIsRecording';
import useParticipants from 'hooks/useParticipants/useParticipants';
import { GridVideoMode1Icon, GridVideoMode2Icon, GridVideoMode3Icon } from 'icons/ChangeGridIcons';
import { ParticipantsIcon } from 'icons/ParticipantsIcon';
import logo from 'images/brand/logo-white-text.svg';
import { useParticipantsGridMode } from 'state/ParticipantsGridMode';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { ButtonToggleChangeCamera } from './ButtonToggleChangeCamera';
import { InviteOthers } from './InviteOthers';
import { RecordingTimer } from './RecordingTimer';
import { VolumeButton } from './VolumeButton';

export default function TopMenuBar() {
  const { room } = useVideoContext();
  const isRecording = useIsRecording();
  const ParticipantList = useParticipants();
  const localParticipant = room!.localParticipant;
  const { setGridMode, gridMode } = useParticipantsGridMode();
  const isMobile = useBreakpointValue({ base: true, xl: false });

  return (
    <>
      <Flex
        h={{ base: '78px', xl: '70px' }}
        w="100%"
        bg={{ base: 'rgba(0,0,0, 0.2)', xl: 'rgba(0,0,0, 0.6)' }}
        alignItems="center"
        pos="absolute"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
        backdropFilter="blur(12px)"
        px={{ base: '20px', lg: '35px' }}
        justifyContent="space-between"
        color="white"
      >
        {isMobile ? (
          <Flex>
            <ButtonToggleChangeCamera />
            <VolumeButton />
          </Flex>
        ) : (
          <Image alt="logo" display={{ base: 'none', xl: 'block' }} src={logo} w={{ base: '120px', md: '140px' }} />
        )}
        <HStack spacing="20px">
          {isMobile ? <EndCallButton /> : null}
          {isRecording ? <RecordingTimer isRecording={isRecording} /> : null}
          {isMobile ? null : (
            <HStack display={{ base: 'none', xl: 'flex' }}>
              <IconButton
                onClick={() => setGridMode('mode 1')}
                aria-label="Change Layout mode 1"
                bg="transparent"
                _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
                _focus={{}}
                icon={<GridVideoMode1Icon active={gridMode === 'mode 1'} />}
              />
              <IconButton
                onClick={() => setGridMode('mode 2')}
                aria-label="Change Layout mode 2"
                bg="transparent"
                _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
                _focus={{}}
                icon={<GridVideoMode2Icon active={gridMode === 'mode 2'} />}
              />
              <IconButton
                onClick={() => setGridMode('mode 3')}
                aria-label="Change Layout mode 3"
                bg="transparent"
                _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
                _focus={{}}
                icon={<GridVideoMode3Icon active={gridMode === 'mode 3'} />}
              />
            </HStack>
          )}
        </HStack>
        {isMobile ? null : (
          <HStack display={{ base: 'none', xl: 'flex' }} spacing="30px" alignItems="center">
            <InviteOthers />
            <Menu>
              <MenuButton
                as={Button}
                _focus={{}}
                _hover={{ bg: 'rgba(255,255,255, 0.2)' }}
                variant=""
                fontWeight="300"
                border="1px solid #FFFFFF"
                borderRadius="9"
                leftIcon={<ParticipantsIcon />}
                rightIcon={<ChevronDownIcon />}
              >
                Participants
              </MenuButton>
              <MenuList
                overflow="hidden"
                border="1px solid rgba(255,255,255, 0.1)"
                bg="#252525"
                borderRadius="10"
                zIndex="1000"
                py="0"
                color="white"
              >
                <MenuItem
                  _focus={{}}
                  borderBottom="1px solid rgba(255,255,255, 0.1)"
                  _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
                >
                  {`${localParticipant.identity} - (You)`}
                </MenuItem>

                {ParticipantList.map(item => (
                  <MenuItem
                    _focus={{}}
                    borderBottom="1px solid rgba(255,255,255, 0.1)"
                    _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
                  >
                    {item.identity}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        )}
      </Flex>
    </>
  );
}
