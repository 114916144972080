import React from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { useAppState } from '../../../state';
import useDevices from '../../../hooks/useDevices/useDevices';
import { Box } from '@chakra-ui/react';
import { InputOutputList } from 'components/InputOutputList';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  return (
    <Box className="inputSelect" w="calc(100% - 34px)">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <InputOutputList
            activeValue={activeSinkId}
            devicesArray={audioOutputDevices}
            changeDeviceFunction={setActiveSinkId}
          />
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">Audio Output</Typography>
          <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
        </>
      )}
    </Box>
  );
}
