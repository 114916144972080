import { Participant } from 'twilio-video';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import { BarsQualityLevel } from 'icons/BarsQualityLevel';

// const STEP = 3;
// const BARS_ARRAY = [0, 1, 2, 3, 4];

export default function NetworkQualityLevel({ participant }: { participant: Participant }) {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  if (networkQualityLevel === null) return null;

  return (
    <>
      <BarsQualityLevel size="14px" level={networkQualityLevel} />
    </>
  );
}
