import { Flex, HStack, useBreakpointValue } from '@chakra-ui/react';
import useRoleContext from 'hooks/useRoleContext/useRoleContext';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import { MobileMenuOptions } from './Buttons/MobileMenuOptions';
import { SettingsMenuButton } from './Buttons/SettingsMenuButton';
import ToggleAudioButton from './Buttons/ToggleAudioButton';
import { ToggleRecordButton } from './Buttons/ToggleRecordButton';
import ToggleScreenShareButton from './Buttons/ToggleScreenShareButton';
import ToggleVideoButton from './Buttons/ToggleVideoButton';

export default function MenuBar() {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { role } = useRoleContext();

  return (
    <Flex
      px={{ base: '30px', lg: '35px' }}
      h={{ base: '90px', xl: '100px' }}
      color="white"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      bg="#1A1A1A"
      pos="fixed"
      zIndex="1"
      bottom="0"
      left="0"
    >
      <HStack
        w={{ base: '100%', xl: 'auto' }}
        justifyContent={{ base: 'space-between', xl: 'flex-start' }}
        spacing={{ base: 'auto', xl: '30px' }}
      >
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />
        {isMobile ? (
          <>
            {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
            {role !== 'patient' && <ToggleRecordButton display={{ base: 'flex', xl: 'none' }} />}
            <MobileMenuOptions />
          </>
        ) : null}
      </HStack>
      {isMobile ? null : (
        <HStack spacing={{ base: 'auto', xl: '30px' }} display={{ base: 'none', xl: 'flex' }}>
          {/* <SecurityButton /> */}
          {role !== 'patient' && <ToggleRecordButton />}
          <ToggleScreenShareButton disabled={isReconnecting} />
          {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
          <SettingsMenuButton />
        </HStack>
      )}

      {isMobile ? null : <EndCallButton display={{ base: 'none', xl: 'flex' }} />}
    </Flex>
  );
}
