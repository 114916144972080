import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

export function InputOutputList({
  activeValue,
  devicesArray,
  changeDeviceFunction,
}: {
  activeValue: string | null | undefined;
  devicesArray: MediaDeviceInfo[];
  changeDeviceFunction: (deviceId: string) => void;
}) {
  const active = devicesArray.find(current => current.deviceId === activeValue);
  return (
    <Menu>
      <MenuButton
        w="100%"
        bg="transparent"
        borderRadius="10"
        justifyContent="flex-start"
        display="flex"
        border="1px solid #002B49"
        _hover={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
        _active={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
        _focus={{}}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        textAlign="start"
        whiteSpace="normal"
      >
        {active ? active?.label : activeValue || ''}
      </MenuButton>
      <MenuList w="calc((30vw - 40px) - 15px)" py="0" borderRadius="10" overflow="hidden">
        {devicesArray.map(device => (
          <MenuItem
            key={device.deviceId}
            _hover={{ bg: '#c5c5c5', color: 'white' }}
            _active={{ bg: '#c5c5c5', color: 'white' }}
            _focus={{}}
            onClick={() => changeDeviceFunction(device.deviceId)}
          >
            {device.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
