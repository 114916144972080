import { Icon, IconProps } from '@chakra-ui/react';

export function CameraIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24.984"
      height="20.805"
      viewBox="0 0 24.984 20.805"
      stroke="#fff"
      {...rest}
    >
      <g transform="translate(-0.5 -3.5)">
        <path
          d="M24.484,21.215A2.089,2.089,0,0,1,22.394,23.3H3.589A2.089,2.089,0,0,1,1.5,21.215V9.724A2.089,2.089,0,0,1,3.589,7.634H7.768L9.858,4.5h6.268l2.089,3.134h4.179a2.089,2.089,0,0,1,2.089,2.089Z"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <g transform="translate(8.883 10.768)">
          <path
            d="M28.37,6V8.87H25.5"
            transform="translate(-18.804 -5.522)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            d="M12.87,10.242a4.306,4.306,0,1,1-1.014-4.478L14.071,7.85"
            transform="translate(-4.505 -4.503)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </Icon>
  );
}
