/* istanbul ignore file */
import { Box } from '@chakra-ui/react';
import { Message } from '@twilio/conversations/lib/message';
import { slimScroll } from 'components/Scroll';
import { ReactNode, useEffect } from 'react';

interface Messages {
  messages: Message[];
  children: ReactNode;
}

export function scrollToBottom(id: string) {
  const element: HTMLElement | null = document.getElementById(id);
  element?.scrollBy({
    top: element?.clientHeight,
    behavior: 'smooth',
  });
}
/*
 * This component is a scrollable container that wraps around the 'MessageList' component.
 * The MessageList will ultimately grow taller than its container as it continues to receive
 * new messages, and users will need to have the ability to scroll up and down the chat window.
 * A "new message" button will be displayed when the user receives a new message, and is not scrolled
 * to the bottom. This button will be hidden if the user clicks on it, or manually scrolls
 * to the bottom. Otherwise, this component will auto-scroll to the bottom when a new message is
 * received, and the user is already scrolled to the bottom.
 *
 * Note that this component is tested with Cypress only.
 */
export function MessageListScrollContainer({ messages, children }: Messages) {
  useEffect(() => {
    if (messages.length) {
      scrollToBottom('message-list-container');
    }
  }, [messages]);

  return (
    <Box
      id="message-list-container"
      h="calc(100% - 171px)"
      w="100%"
      overflow="auto"
      mx="-15px"
      px="15px"
      pb="10px"
      css={{ '::-webkit-scrollbar': { display: 'none' } }}
    >
      {children}
    </Box>
  );
}
