import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function AvatarIcon({ ...rest }: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="73px" height="73px" viewBox="0 0 99.996 100" {...rest}>
      <g id="user" transform="translate(0 0)">
        <path
          id="Caminho_3229"
          data-name="Caminho 3229"
          d="M26.75,71.3,11.831,79.443a13.407,13.407,0,0,0-2.394,1.7,49.968,49.968,0,0,0,64.2.166,13.236,13.236,0,0,0-2.63-1.762L55.033,71.563a6.092,6.092,0,0,1-3.368-5.449V59.846A24.133,24.133,0,0,0,53.177,57.9,36.76,36.76,0,0,0,58.143,47.9,5.018,5.018,0,0,0,61.7,43.122V36.431a5,5,0,0,0-1.672-3.708V23.052S62.016,8,41.633,8s-18.4,15.051-18.4,15.051v9.672a4.988,4.988,0,0,0-1.672,3.708v6.691a5.016,5.016,0,0,0,2.313,4.209,33.2,33.2,0,0,0,6.049,12.515V65.96A6.1,6.1,0,0,1,26.75,71.3Z"
          transform="translate(8.369 7.095)"
          fill="#fff"
        />
        <g id="Grupo_2172" data-name="Grupo 2172" transform="translate(0 0)">
          <path
            id="Caminho_3230"
            data-name="Caminho 3230"
            d="M50.855.007A49.969,49.969,0,0,0,17.826,88.226,13.285,13.285,0,0,1,20.2,86.54L35.117,78.4a6.1,6.1,0,0,0,3.175-5.349V66.94a33.17,33.17,0,0,1-6.049-12.515,5.018,5.018,0,0,1-2.313-4.209V43.525A5,5,0,0,1,31.6,39.817V30.145S29.615,15.094,50,15.094s18.4,15.051,18.4,15.051v9.672a4.988,4.988,0,0,1,1.672,3.708v6.691a5.018,5.018,0,0,1-3.558,4.774A36.759,36.759,0,0,1,61.542,65,24.127,24.127,0,0,1,60.03,66.94v6.268A6.09,6.09,0,0,0,63.4,78.657l15.975,7.987A13.3,13.3,0,0,1,82,88.4,49.988,49.988,0,0,0,50.855.007Z"
            transform="translate(0 0)"
            fill="#313131"
          />
        </g>
      </g>
    </Icon>
  );
}
