import { IconButton, IconButtonProps } from '@chakra-ui/react';

export function StyledIconButton({ ...rest }: IconButtonProps) {
  return (
    <IconButton
      w={{ base: '38px', xl: '50px' }}
      h={{ base: '38px', xl: '50px' }}
      bg="rgba(255,255,255, 0.1)"
      borderRadius={{ base: '15', xl: '12' }}
      _focus={{}}
      _active={{ bg: 'rgba(255,255,255, 0.2)' }}
      _hover={{ bg: 'rgba(255,255,255, 0.2)' }}
      {...rest}
    />
  );
}
