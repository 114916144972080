import { Box, Flex, HStack, Switch, Text } from '@chakra-ui/react';
import useDevices from 'hooks/useDevices/useDevices';
import useLocalAudioToggle from 'hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from 'hooks/useLocalVideoToggle/useLocalVideoToggle';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import CamIcon from 'icons/CamIcon';
import MicIcon from 'icons/MicIcon';
import { useCallback, useRef } from 'react';

export function ControlsPainel({ roomName, disableButtons }: { roomName: string; disableButtons: boolean }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Box>
      <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold">
        Join{' '}
        <Text as="span" color="brand.500">
          Consult
        </Text>
      </Text>
      <Text fontSize={{ base: '16', md: '20' }} mb="25px">{`Consult ID: ${roomName}`}</Text>
      <Flex alignItems="center" mr="25px">
        <Switch
          colorScheme="yellow"
          isChecked={isVideoEnabled}
          isDisabled={!hasVideoInputDevices || disableButtons}
          onChange={() => toggleVideo()}
          mr="10px"
        />
        <Text fontSize="18px">Join with video</Text>
      </Flex>
      <Flex alignItems="center">
        <Switch
          colorScheme="yellow"
          isChecked={isAudioEnabled}
          isDisabled={!hasAudioTrack || disableButtons}
          onChange={() => toggleAudioEnabled()}
          mr="10px"
        />
        <Text fontSize="18">Join with computer audio</Text>
      </Flex>
      <HStack mb="10px" mt={{ base: '20px', md: '15px' }}>
        <Box
          bg={!isAudioEnabled ? '#c5c5c5' : 'brand.500'}
          transition="0.3s"
          css={{ aspectRatio: '1' }}
          borderRadius="15px"
          p="13px"
        >
          <MicIcon isDisabled={!isAudioEnabled} fill="white" width="24px" height="24px" />
        </Box>
        <Box
          bg={!isVideoEnabled ? '#c5c5c5' : 'brand.500'}
          transition="0.3s"
          css={{ aspectRatio: '1' }}
          borderRadius="15px"
          p="13px"
        >
          <CamIcon isDisabled={!isVideoEnabled} fill="white" width="26px" height="24px" />
        </Box>
      </HStack>
    </Box>
  );
}
