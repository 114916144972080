import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import SettingsIcon from 'icons/SettingsIcon';
import { useState } from 'react';
import { useAppState } from 'state';
import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';

export default function SettingsMenu() {
  const { roomType } = useAppState();
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          size="custom"
          bg="transparent"
          borderRadius="10px"
          border="1px solid #002B49"
          _hover={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
          _active={{ bg: '#c5c5c5', color: 'white', border: '1px solid transparent' }}
          _focus={{}}
          leftIcon={<SettingsIcon fill="currentColor" />}
        >
          Settings
        </MenuButton>
        <MenuList py="0" borderRadius="10" _focus={{}} overflow="hidden">
          <MenuItem
            _focus={{}}
            onClick={() => setAboutOpen(true)}
            fontSize="18"
            _hover={{ bg: '#c5c5c5', color: 'white' }}
          >
            About
          </MenuItem>
          <MenuItem
            _focus={{}}
            onClick={() => setDeviceSettingsOpen(true)}
            fontSize="18"
            _hover={{ bg: '#c5c5c5', color: 'white' }}
          >
            Audio & Video
          </MenuItem>
          {roomType !== 'peer-to-peer' && roomType !== 'go' && (
            <MenuItem
              _focus={{}}
              onClick={() => setConnectionSettingsOpen(true)}
              fontSize="18"
              _hover={{ bg: '#c5c5c5', color: 'white' }}
            >
              Connection
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
        }}
      />
    </>
  );
}
