import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import AboutDialog from 'components/AboutDialog/AboutDialog';
import useChatContext from 'hooks/useChatContext/useChatContext';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { DotsIcon } from 'icons/DotsIcon';

export function MobileMenuOptions() {
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { isChatWindowOpen, setIsChatWindowOpen } = useChatContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  return (
    <>
      <Menu>
        <MenuButton
          display={{ base: 'flex', xl: 'none' }}
          as={IconButton}
          aria-label="mobile menu"
          icon={<DotsIcon width="20px" height="4px" fill="#fff" />}
          bg="rgba(255,255,255, 0.1)"
          variant=""
          _focus={{}}
          _active={{ bg: 'rgba(255,255,255, 0.15)' }}
          h="38px"
          w="38px"
          borderRadius="15"
        />
        <MenuList
          overflow="hidden"
          border="1px solid rgba(255,255,255, 0.1)"
          bg="#252525"
          borderRadius="10"
          zIndex="1000"
          py="0"
          color="white"
        >
          <MenuItem
            _focus={{}}
            borderBottom="1px solid rgba(255,255,255, 0.1)"
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={onOpen}
          >
            About
          </MenuItem>
          <MenuItem
            _focus={{}}
            borderBottom="1px solid rgba(255,255,255, 0.1)"
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={() => setIsBackgroundSelectionOpen(true)}
          >
            Choose Background....
          </MenuItem>
          <MenuItem
            _focus={{}}
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={() => {
              VideoRoomMonitor.toggleMonitor();
            }}
          >
            Room Monitor
          </MenuItem>
        </MenuList>
      </Menu>
      <AboutDialog open={isOpen} onClose={onClose} />
    </>
  );
}
