import { Icon, IconProps } from '@chakra-ui/react';

export default function CamIcon({ isDisabled = false, ...rest }: IconProps & { isDisabled?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 21.379 30.999" {...rest}>
      <g transform="translate(7037 -8807)">
        <path
          d="M20.395,27.237a1.77,1.77,0,0,0-1.863.164L16.9,28.613a4.459,4.459,0,0,0-4.431-4.008H4.453A4.458,4.458,0,0,0,0,29.058v7.125a4.458,4.458,0,0,0,4.453,4.453h8.015A4.459,4.459,0,0,0,16.9,36.628l1.63,1.215a1.781,1.781,0,0,0,2.85-1.425V28.83A1.77,1.77,0,0,0,20.395,27.237ZM15.14,36.183a2.672,2.672,0,0,1-2.672,2.672H4.453a2.672,2.672,0,0,1-2.672-2.672V29.058a2.672,2.672,0,0,1,2.672-2.672h8.015a2.672,2.672,0,0,1,2.672,2.672Zm4.453.229-2.672-1.991v-3.6l2.672-1.991Z"
          transform="translate(-7037 8789.395)"
          fill="#fff"
        />
        {isDisabled ? (
          <rect
            width="1.973"
            height="34.655"
            rx="0.986"
            transform="translate(-7019.672 8807) rotate(30)"
            fill="#e52b51"
          />
        ) : null}
      </g>
    </Icon>
  );
}
