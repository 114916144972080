import { Icon, IconProps } from '@chakra-ui/react';

export function SmileIcon({ ...rest }: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" {...rest}>
      <path
        id="smile"
        d="M7.5,15A7.5,7.5,0,1,1,15,7.5,7.5,7.5,0,0,1,7.5,15Zm0-13.75A6.25,6.25,0,1,0,13.75,7.5,6.25,6.25,0,0,0,7.5,1.25Zm3.541,8.591a.625.625,0,0,0-.831-.934A4.693,4.693,0,0,1,7.5,10,4.693,4.693,0,0,1,4.791,8.909a.625.625,0,1,0-.832.932A5.909,5.909,0,0,0,7.5,11.25a5.909,5.909,0,0,0,3.541-1.409ZM3.75,6.25c0,.625.559.625,1.25.625s1.25,0,1.25-.625a1.25,1.25,0,0,0-2.5,0Zm5,0c0,.625.559.625,1.25.625s1.25,0,1.25-.625a1.25,1.25,0,0,0-2.5,0Z"
        fill="#8c8c8c"
      />
    </Icon>
  );
}
