import { MenuItem, MenuItemOption } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { InterfaceType } from 'typescript';

export function SubMenu({
  title,
  optionsArray,
  property = 'deviceId',
  activeOption,
  isOpen,
  onClose,
  handleChange,
}: {
  title: string;
  optionsArray: MediaDeviceInfo[] | any;
  isOpen: boolean;
  property?: string;
  onClose?: () => void | undefined;
  activeOption: string | undefined | null;
  handleChange: (a: string) => void;
}) {
  function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key];
  }
  return (
    <MenuItem
      _hover={{ bg: isOpen ? 'transparent' : 'rgba(255,255,255, 0.1)' }}
      _focus={{}}
      borderBottom="1px solid rgba(255,255,255, 0.1)"
      flexDirection="column"
      alignItems="flex-start"
      onClick={onClose}
      closeOnSelect={false}
    >
      {title}
      <motion.div
        style={{
          overflow: 'hidden',
          height: '0',
          width: '100%',
        }}
        transition={{ duration: 0.2, ease: 'easeIn' }}
        animate={{ height: isOpen ? 'auto' : '0' }}
      >
        {optionsArray.map((item: any) => (
          <MenuItemOption
            width="100%"
            _active={{}}
            _focus={{}}
            closeOnSelect={false}
            key={getProperty(item, property)}
            isChecked={activeOption === getProperty(item, property)}
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={e => {
              e.stopPropagation();
              handleChange(getProperty(item, property));
            }}
          >
            {item.label ? item.label : getProperty(item, property)}
          </MenuItemOption>
        ))}
      </motion.div>
    </MenuItem>
  );
}
