import { Box, Grid, Text } from '@chakra-ui/react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ModalContentCustom } from 'components/ModalContentCustom';
import { slimScroll } from 'components/Scroll';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { backgroundConfig } from '../VideoProvider/useBackgroundSettings/useBackgroundSettings';
import BackgroundThumbnail from './BackgroundThumbnail/BackgroundThumbnail';

function BackgroundSelectionDialog() {
  const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();

  const imageNames = backgroundConfig.imageNames;
  const images = backgroundConfig.images;

  return (
    <ModalContentCustom
      isOpen={isBackgroundSelectionOpen}
      onClose={() => setIsBackgroundSelectionOpen(false)}
      backdropFilter="blur(8px)"
      p={{ base: '25px 5px', xl: '30px 5px' }}
      minW={{ base: '90vw', md: '80vw', xl: '55vw', '2xl': '40vw' }}
      maxW={{ base: '90vw', md: '80vw', xl: '55vw', '2xl': '40vw' }}
      maxH="80vh"
      borderRadius="20px"
    >
      <Box
        w="100%"
        p={{ base: '0 15px', xl: '0 35px' }}
        css={slimScroll}
        maxH={{ base: 'calc(80vh - 50px)', md: 'calc(80vh - 60px)' }}
        overflow="auto"
      >
        <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold" mb="10px">
          Backgrounds
        </Text>
        <Grid templateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr' }} gap="20px" w="100%">
          <BackgroundThumbnail thumbnail={'none'} name={'None'} />
          <BackgroundThumbnail thumbnail={'blur'} name={'Blur'} />
          {images.map((image, index) => (
            <BackgroundThumbnail
              thumbnail={'image'}
              name={imageNames[index]}
              index={index}
              imagePath={image}
              key={image}
            />
          ))}
        </Grid>
      </Box>
    </ModalContentCustom>
  );
}

export default BackgroundSelectionDialog;
