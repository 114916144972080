import { RoleContext } from 'components/RoleProvider';
import { useContext } from 'react';

export default function useRoleContext() {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useRoleContext must be used within a RoleProvider');
  }
  return context;
}
