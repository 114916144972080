import React from 'react';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Flex, Grid, HStack } from '@chakra-ui/react';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';

// const useStyles = makeStyles({
//   messageContainer: {
//     borderRadius: '16px',
//     display: 'inline-flex',
//     alignItems: 'center',
//     padding: '0.5em 0.8em 0.6em',
//     margin: '0.3em 0 0',
//     wordBreak: 'break-word',
//     backgroundColor: '#E1E3EA',
//     hyphens: 'auto',
//     whiteSpace: 'pre-wrap',
//   },
//   isLocalParticipant: {
//     backgroundColor: '#CCE4FF',
//   },
// });

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
  author: string;
  showLetter: boolean;
  colors?: {
    author: string;
    color: string;
  }[];
}

function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({ body, isLocalParticipant, colors, author, showLetter }: TextMessageProps) {
  // const classes = useStyles();
  const firstLetter = author.substr(0, 1);
  const findColor = colors ? colors.find(current => current.author === author) : null;

  return (
    <Flex
      alignItems="center"
      flexDir={isLocalParticipant ? 'row-reverse' : 'row'}
      justifyContent={isLocalParticipant ? 'end' : 'flex-start'}
      mt="8px"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        w="25px"
        h="25px"
        bg={findColor ? findColor.color : ''}
        borderRadius="5"
        textTransform="uppercase"
        ml={isLocalParticipant ? '8px' : '0'}
        mr={isLocalParticipant ? '0' : '8px'}
        color="white"
        visibility={showLetter ? 'visible' : 'hidden'}
      >
        {firstLetter}
      </Flex>
      <Flex
        borderRadius={isLocalParticipant ? '10px 10px 3px 10px' : '10px 10px 10px 3px'}
        alignItems="center"
        padding="0.5em 0.8em 0.6em"
        wordBreak="break-word"
        backgroundColor={isLocalParticipant ? 'rgba(0, 163, 221, 0.1)' : 'rgba(242, 242, 242, 0.7)'}
        whiteSpace="pre-wrap"
        maxW="calc(100% - 33px)"
      >
        <Box fontSize="16">{addLinks(body)}</Box>
      </Flex>
    </Flex>
  );
}
