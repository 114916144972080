import AvatarIcon from '../../../../icons/AvatarIcon';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { Flex, HStack, Text } from '@chakra-ui/react';

export default function LocalVideoPreview({ identity }: { identity?: string | undefined }) {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return (
    <>
      <Flex
        pos="relative"
        bg="#090909"
        justifyContent="center"
        alignItems="center"
        css={{ aspectRatio: 'calc(16 / 9)' }}
      >
        {videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <AvatarIcon />}
        {identity ? (
          <HStack color="#fff" pos="absolute" p="10px 10px 8px 8px" bottom="0" left="0" bg="rgba(255,255,255, 0.2)">
            <LocalAudioLevelIndicator />
            <Text fontSize="15">{identity}</Text>
          </HStack>
        ) : null}
      </Flex>
    </>
  );
}
