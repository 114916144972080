import { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';

import { useLocation, useHistory } from 'react-router-dom';

import LogoSVG from '../../images/brand/logo.svg';
import {
  Button as ChakraButton,
  Image,
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { Loading } from '../Loading';

export default function LoginPage() {
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  // useEffect(() => {
  //   login();
  // }, [])

  const login = () => {
    setAuthError(null);
    setLogingIn(true);
    signIn?.(passcode)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
        setLogingIn(false);
      })
      .catch(err => {
        setLogingIn(false);
        setAuthError(err);
      });
  };

  const [logingIn, setLogingIn] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user || !isAuthEnabled) {
    history.replace('/');
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <Box>
      {process.env.REACT_APP_SET_AUTH === 'passcode' && (
        <Flex w="100%" minH="100vh" alignItems={'center'} justifyContent="center">
          <Box>
            <Image mx="auto" h={{ base: '60px', md: '70px' }} src={LogoSVG} mb="40px" />
            <Box
              p={{ base: '30px', md: '40px' }}
              w={{ base: '95vw', md: '430px' }}
              bg="white"
              borderRadius="5px"
              boxShadow="0px 0px 50px rgba(0,0,0,.05)"
            >
              <Heading mb="24px" lineHeight={'1em'} fontSize={{ base: '25', md: '30' }}>
                Join{' '}
                <Text as="span" color="brand.500">
                  Consult
                </Text>
              </Heading>

              <form onSubmit={handleSubmit}>
                <FormControl mb="24px">
                  <FormLabel mb="16px">Enter passcode to join the consult</FormLabel>
                  <Input
                    size="lg"
                    variant="outline"
                    border="1px solid #babacc"
                    _focus={{ border: '1px solid #F6BA55' }}
                    type="password"
                    w="100%"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                  />
                </FormControl>

                <Flex justifyContent="flex-end" alignItems="center">
                  <Box mr="15px">
                    <Loading isLoading={logingIn} />
                  </Box>
                  <ChakraButton
                    variant="branded"
                    size="custom"
                    _focus={{}}
                    _hover={{ bg: '#F6BA55' }}
                    disabled={!passcode.length}
                    type="submit"
                  >
                    Join Consult
                  </ChakraButton>
                </Flex>
              </form>
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  );
}
