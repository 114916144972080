import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import { Stack, StackProps, Text } from '@chakra-ui/react';
import { ScreenShareFilledIcon } from 'icons/ScreenShareFilledIcon';
import { StyledIconButton } from './StyledIconButton';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton({ disabled, ...rest }: { disabled?: boolean } & StackProps) {
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare, isSharingScreen } = useVideoContext();
  // const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  // const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;
  const isDisabled = disabled || !isScreenShareSupported;

  let tooltipMessage = '';

  // if (disableScreenShareButton) {
  //   tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  // }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Stack alignItems="center" {...rest}>
      <StyledIconButton
        aria-label="Record Buttom"
        onClick={toggleScreenShare}
        icon={
          <ScreenShareFilledIcon
            isSharing={isSharingScreen}
            width={{ base: '18px', lg: '24px' }}
            height={{ base: '18px', lg: '24px' }}
          />
        }
        disabled={isDisabled}
      />
      <Text textAlign="center" fontSize={{ base: '10', lg: '16' }}>
        {isDisabled ? 'No Share Screen' : isSharingScreen ? 'Sharing Screen' : 'Share Screen'}
      </Text>
    </Stack>
  );
}
