import React, { useEffect, useRef, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { interval } from 'd3-timer';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';

let clipId = 0;
const getUniqueClipId = () => clipId++;

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

function AudioLevelIndicator({
  audioTrack,
  width = '16',
  height = '16',
  fill = '#fff',
}: {
  audioTrack?: AudioTrack;
  width?: string;
  height?: string;
  fill?: string;
}) {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && SVGClipElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(19.21, Math.max(0, Math.log10(values / length / 3) * 13));

        SVGClipElement?.setAttribute('y', String(19.21 - volume));
      }, 100);

      return () => {
        SVGClipElement.setAttribute('y', '19.21');
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  // Each instance of this component will need a unique HTML ID
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

  return isTrackEnabled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.451 30">
      <defs>
        <clipPath id={clipPathId}>
          <rect ref={SVGRectRef} x="0" y="19.21" width={width} height={height} />
        </clipPath>
      </defs>
      <g transform="translate(-51.274 -1005)">
        <rect
          clipPath={`url(#${clipPathId})`}
          width="9.474"
          height="19.21"
          rx="4.737"
          transform="translate(56.249 1006.316)"
          fill="#f6ba55"
        />
        <g transform="translate(51.274 1005)">
          <path
            d="M19.952,260.711a.895.895,0,0,0-1.789,0,7.936,7.936,0,1,1-15.872,0,.895.895,0,0,0-1.789,0,9.738,9.738,0,0,0,8.831,9.684V272.8H6.012a.895.895,0,1,0,0,1.789h8.43a.895.895,0,0,0,0-1.789h-3.32v-2.409a9.738,9.738,0,0,0,8.831-9.684Zm0,0"
            transform="translate(-0.501 -244.593)"
            fill={fill}
          />
          <path
            d="M74.443,21.848a5.735,5.735,0,0,0,5.729-5.729V5.729a5.729,5.729,0,0,0-11.457,0v10.39A5.735,5.735,0,0,0,74.443,21.848ZM70.5,5.729a3.939,3.939,0,0,1,7.879,0v10.39a3.939,3.939,0,1,1-7.879,0Zm0,0"
            transform="translate(-64.717 -0.001)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.451 30.999">
      <g transform="translate(-578 -599)">
        <g transform="translate(3 -79)">
          <g id="microphone" transform="translate(575 678)">
            <path
              d="M19.952,260.711a.895.895,0,0,0-1.789,0,7.936,7.936,0,1,1-15.872,0,.895.895,0,0,0-1.789,0,9.738,9.738,0,0,0,8.831,9.684V272.8H6.012a.895.895,0,1,0,0,1.789h8.43a.895.895,0,0,0,0-1.789h-3.32v-2.409a9.738,9.738,0,0,0,8.831-9.684Zm0,0"
              transform="translate(-0.501 -244.593)"
              fill={fill}
            />
            <path
              d="M74.443,21.848a5.735,5.735,0,0,0,5.729-5.729V5.729a5.729,5.729,0,0,0-11.457,0v10.39A5.735,5.735,0,0,0,74.443,21.848ZM70.5,5.729a3.939,3.939,0,0,1,7.879,0v10.39a3.939,3.939,0,1,1-7.879,0Zm0,0"
              transform="translate(-64.717 -0.001)"
              fill={fill}
            />
          </g>
        </g>
        <rect width="1.973" height="34.655" rx="0.986" transform="translate(595.328 599) rotate(30)" fill="#e52b51" />
      </g>
    </svg>
  );
}

export default React.memo(AudioLevelIndicator);
