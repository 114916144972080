import { Icon, IconProps } from '@chakra-ui/react';

export function SendIcon({ ...rest }: IconProps) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="14.998" height="15" viewBox="0 0 14.998 15" {...rest}>
      <path
        d="M14.451.551a1.854,1.854,0,0,0-1.75-.5L2.7,2.158a3.122,3.122,0,0,0-1.783,5.3L1.991,8.532a.625.625,0,0,1,.183.443v1.98a1.853,1.853,0,0,0,.188.8l-.005,0,.016.016a1.875,1.875,0,0,0,.851.848l.016.016,0-.005a1.853,1.853,0,0,0,.8.188h1.98a.625.625,0,0,1,.442.183L7.543,14.08a3.1,3.1,0,0,0,2.2.921,3.156,3.156,0,0,0,1-.165,3.083,3.083,0,0,0,2.09-2.491L14.946,2.322a1.859,1.859,0,0,0-.5-1.771ZM2.876,7.649,1.8,6.576A1.838,1.838,0,0,1,1.35,4.655,1.861,1.861,0,0,1,2.913,3.388l9.9-2.084L3.423,10.7V8.974A1.861,1.861,0,0,0,2.876,7.649Zm8.732,4.481A1.875,1.875,0,0,1,8.427,13.2L7.352,12.125a1.861,1.861,0,0,0-1.324-.549H4.306L13.7,2.188Z"
        transform="translate(-0.003 -0.001)"
        fill="#fff"
      />
    </Icon>
  );
}
