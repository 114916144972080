import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { SubMenu } from 'components/MenuBar/Buttons/SubMenu';
import { slimScroll } from 'components/Scroll';
import { DotsIcon } from 'icons/DotsIcon';
import { useState } from 'react';

interface ChatPermission {
  permissionName: string;
}

export function MenuChatConfig() {
  const [activeSetting, setActiveSetting] = useState('Everyone and anyone directly');

  const ChatPermissions: ChatPermission[] = [
    {
      permissionName: 'No one',
    },
    {
      permissionName: 'Host and co-hosts',
    },
    {
      permissionName: 'Everyone',
    },
    {
      permissionName: 'Everyone and anyone directly',
    },
  ];

  const handleChange = (permissionName: string) => {
    setActiveSetting(permissionName);
  };

  return (
    <Menu>
      <MenuButton as={Box} _active={{ bg: '#F2F2F2' }} borderRadius="5" _hover={{ bg: '#F2F2F2' }}>
        <Flex justifyContent="center" w="25px" h="25px" alignItems="center">
          <DotsIcon />
        </Flex>
      </MenuButton>
      <MenuList
        bg="#F7FCFF"
        borderRadius="10"
        py="0"
        boxShadow="0 0 1em rgba(0,0,0, 0.14)"
        maxH="350px"
        overflow="auto"
        maxW="300px"
        css={slimScroll}
        display="flex"
        flexWrap="wrap"
      >
        <MenuItem
          //   key={`emoji-${index}`}
          py="10px"
          borderRadius="5"
          transition="0.3s"
          _focus={{}}
          _hover={{ bg: '#F2F2F2' }}
          //   onClick={() => control.onToggle()}
          closeOnSelect={true}
          cursor="pointer"
        >
          Save Chat
        </MenuItem>
        <SubMenu
          title="Participants can chat with:"
          activeOption={activeSetting}
          isOpen={true}
          handleChange={handleChange}
          optionsArray={ChatPermissions}
          property="permissionName"
        />
      </MenuList>
    </Menu>
  );
}
