import { Box, Button, Flex, IconButton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ModalContentCustom } from 'components/ModalContentCustom';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { CopyIcon } from 'icons/CopyIcon';
import { InviteIcon } from 'icons/InviteIcon';
import Ripples from 'react-ripples';

export function InviteOthers() {
  const { room } = useVideoContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex alignItems="center" cursor="pointer" onClick={() => onOpen()}>
        <IconButton _focus={{}} aria-label="Invite Button" variant="" icon={<InviteIcon />} />
        <Text color="#f6ba55">Invite others</Text>
      </Flex>
      <ModalContentCustom
        isOpen={isOpen}
        onClose={onClose}
        backdropFilter="none"
        p={{ base: '25px', md: '40px' }}
        minW={{ base: '90vw', lg: '80vw', xl: '55vw', '2xl': '25vw' }}
        maxW={{ base: '95vw', xl: '55vw', '2xl': '25vw' }}
        borderRadius="20px"
      >
        <Flex flexDir="column" w="100%">
          <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold" mb="20px">
            Joining{' '}
            <Text as="span" color="brand.500">
              Info
            </Text>
          </Text>
          <Text mb="10px">Share this meeting link to invite others:</Text>
          <Ripples color="rgba(0,0,0, 0.1)">
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              py="10px"
              px="20px"
              transition="0.3s"
              bg="rgba(0,0,0, 0.05)"
              borderRadius="10"
              onClick={() => {
                navigator.clipboard.writeText(window.location.origin + '/room/' + room?.name);
              }}
            >
              <Text id="roomLink">{window.location.origin + '/room/' + room?.name}</Text>
              <Box ml="10px" p="5px">
                <CopyIcon />
              </Box>
            </Flex>
          </Ripples>
          <Flex justifyContent="flex-end" mt="25px">
            <Button size="custom" ml="auto" variant="branded" onClick={onClose}>
              Done
            </Button>
          </Flex>
        </Flex>
      </ModalContentCustom>
    </>
  );
}
