import { Icon, IconProps } from '@chakra-ui/react';

export function ChatFilledIcon({ size = '19', ...rest }: IconProps & { size?: string }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="19.001px" height="19px" viewBox="0 0 19.001 19" {...rest}>
      <path
        d="M15.834,0H3.167A3.167,3.167,0,0,0,0,3.167v9.5a3.167,3.167,0,0,0,3.167,3.167h2.3l3.524,2.979a.792.792,0,0,0,1.023,0l3.529-2.979h2.3A3.167,3.167,0,0,0,19,12.667v-9.5A3.167,3.167,0,0,0,15.834,0ZM5.542,3.958H9.5a.792.792,0,1,1,0,1.583H5.542a.792.792,0,1,1,0-1.583Zm7.917,7.917H5.542a.792.792,0,0,1,0-1.583h7.917a.792.792,0,1,1,0,1.583Zm0-3.167H5.542a.792.792,0,1,1,0-1.583h7.917a.792.792,0,1,1,0,1.583Z"
        fill="#fff"
      />
    </Icon>
  );
}
