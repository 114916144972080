import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import DeviceSelectionDialog from 'components/DeviceSelectionDialog/DeviceSelectionDialog';
import useDevices from 'hooks/useDevices/useDevices';
import useLocalVideoToggle from 'hooks/useLocalVideoToggle/useLocalVideoToggle';
import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack';
import { CamFilledIcon } from 'icons/CamFilledIcon';
import { useCallback, useRef, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { StyledIconButton } from './StyledIconButton';
import { SubMenu } from './SubMenu';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { hasVideoInputDevices, videoInputDevices } = useDevices();
  const [openMenu, setOpenMenu] = useState('');
  const { localTracks, setIsBackgroundSelectionOpen } = useVideoContext();
  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  const isMobile = useBreakpointValue({ base: true, xl: false });

  function SelectVideoDevice(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  const closeDevicesMenu = () => {
    setOpenMenu(state => {
      if (state === 'devices') return '';
      return 'devices';
    });
  };

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <>
      <ChakraMenu onClose={() => setOpenMenu('')}>
        <Flex alignItems="center">
          <Stack alignItems="center">
            <StyledIconButton
              aria-label="Video Button"
              onClick={toggleVideo}
              icon={
                <CamFilledIcon
                  width={{ base: '22px', lg: '30px' }}
                  height={{ base: '22px', lg: '30px' }}
                  isDisabled={!isVideoEnabled}
                />
              }
            />

            <Text textAlign="center" fontSize={{ base: '10', lg: '16' }}>
              {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Show Video'}
            </Text>
          </Stack>
          {isMobile ? null : (
            <MenuButton>
              <Box
                p="0px 4px"
                ml="8px"
                borderRadius="5"
                bg="#252525"
                _active={{ bg: 'rgba(255,255,255, 0.2)' }}
                _hover={{ bg: 'rgba(255,255,255, 0.2)' }}
              >
                <ChevronUpIcon w="15px" h="15px" />
              </Box>
            </MenuButton>
          )}
        </Flex>
        <MenuList
          overflow="hidden"
          border="1px solid rgba(255,255,255, 0.1)"
          bg="#252525"
          borderRadius="10"
          zIndex="1000"
          py="0"
          color="white"
        >
          <SubMenu
            title="Select Camera"
            activeOption={localVideoInputDeviceId}
            isOpen={openMenu === 'devices'}
            onClose={closeDevicesMenu}
            handleChange={SelectVideoDevice}
            optionsArray={videoInputDevices}
          />

          <MenuItem
            _focus={{}}
            borderBottom="1px solid rgba(255,255,255, 0.1)"
            _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
            onClick={() => setIsBackgroundSelectionOpen(true)}
          >
            Choose Background....
          </MenuItem>
          <MenuItem _focus={{}} _hover={{ bg: 'rgba(255,255,255, 0.1)' }} onClick={onOpen}>
            Video settings...
          </MenuItem>
        </MenuList>
      </ChakraMenu>
      <DeviceSelectionDialog audioSettings={false} videoSettings={true} open={isOpen} onClose={onClose} />
    </>
  );
}
