import { useToast } from '@chakra-ui/react';
import { defaultToastStyle } from 'chakra/toastStyle';
import useRoomState from 'hooks/useRoomState/useRoomState';
import { useEffect, useRef, useState } from 'react';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';

enum Snackbars {
  none,
  recordingStarted,
  recordingInProgress,
  recordingFinished,
}

export default function Notifications() {
  const toast = useToast();

  const [activeSnackbar, setActiveSnackbar] = useState(Snackbars.none);
  const prevIsRecording = useRef<boolean | null>(null);
  const isRecording = useIsRecording();
  const roomState = useRoomState();

  // isDisconected Notificantions

  useEffect(() => {
    if (roomState === 'reconnecting') {
      toast({
        ...defaultToastStyle,
        title: 'Connection Lost:',
        description: 'Reconnecting to room...',
        status: 'error',
      });
    }
  }, [roomState]);

  // isRecording notifications

  useEffect(() => {
    if (activeSnackbar === Snackbars.recordingStarted) {
      toast({
        ...defaultToastStyle,
        title: 'Appointment has started.',
      });
    } else if (activeSnackbar === Snackbars.recordingInProgress) {
      toast({
        ...defaultToastStyle,
        title: 'Appointment is in progress.',
      });
    } else if (activeSnackbar === Snackbars.recordingFinished) {
      toast({
        ...defaultToastStyle,
        title: 'Appointment Complete',
        description: 'Recordings will be available once this room has ended.',
      });
    }
  }, [activeSnackbar]);

  useEffect(() => {
    // Show "Recording in progress" snackbar when a user joins a room that is recording
    if (isRecording && prevIsRecording.current === null) {
      setActiveSnackbar(Snackbars.recordingInProgress);
    }
    if (isRecording && prevIsRecording.current === false) {
      setActiveSnackbar(Snackbars.recordingStarted);
    }
    if (!isRecording && prevIsRecording.current === true) {
      setActiveSnackbar(Snackbars.recordingFinished);
    }
  }, [isRecording]);

  useEffect(() => {
    prevIsRecording.current = isRecording;
  }, [isRecording]);

  return <></>;
}
