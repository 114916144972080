import { Icon, IconProps } from '@chakra-ui/react';

export function RecordIcon({
  size = '21.122px',
  isRecording = false,
  ...rest
}: IconProps & { size?: string; isRecording?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21.122 21.122" {...rest}>
      <g id="Grupo_2353" data-name="Grupo 2353" transform="translate(-839.439 -1009.449)">
        <path
          d="M10.561,0A10.561,10.561,0,1,0,21.122,10.561,10.561,10.561,0,0,0,10.561,0Zm0,19.362a8.8,8.8,0,1,1,8.8-8.8A8.8,8.8,0,0,1,10.561,19.362Z"
          transform="translate(839.439 1009.449)"
          fill="#fff"
        />
        <path
          d="M7,0a7,7,0,1,0,7,7A7,7,0,0,0,7,0Z"
          transform="translate(843.012 1013.023)"
          fill={isRecording ? '#EA3535' : '#fff'}
        />
      </g>
    </Icon>
  );
}
