import { Stack, Text } from '@chakra-ui/react';
import { StyledIconButton } from 'components/MenuBar/Buttons/StyledIconButton';
import { ChatFilledIcon } from 'icons/ChatFilledIcon';
import { useEffect, useState } from 'react';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const ANIMATION_DURATION = 700;

export default function ToggleChatButton() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);
      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <>
      <Stack alignItems="center">
        <StyledIconButton
          aria-label="Video Buttom"
          onClick={toggleChatWindow}
          icon={<ChatFilledIcon width={{ base: '17px', xl: '19px' }} height={{ base: '17px', xl: '19px' }} />}
          bg={isChatWindowOpen ? 'brand.500' : 'rgba(255,255,255, 0.1)'}
          _hover={isChatWindowOpen ? { bg: '#f3a725' } : { bg: 'rgba(255,255,255, 0.2)' }}
        />

        <Text textAlign="center" fontSize={{ base: '10', lg: '16' }}>
          {isChatWindowOpen ? 'Close Chat' : 'Open Chat'}
        </Text>
      </Stack>
    </>
  );
}
