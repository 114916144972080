import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { VolumeIcon } from 'icons/VolumeIcon';
import { useState } from 'react';

export function VolumeButton() {
  const [volume, setVolume] = useState(1);

  function handleChange(value: number) {
    let audios = [...document.getElementsByTagName('audio')];
    audios.forEach(audio => (audio.volume = value));
    setVolume(value);
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Change Layout mode 1"
        bg="rgba(255,255,255, 0.1)"
        borderRadius="15"
        h="42px"
        w="42px"
        _hover={{}}
        _active={{ bg: 'rgba(255,255,255, 0.2)' }}
        _focus={{}}
        icon={<VolumeIcon volume={volume} width="21px" height="21px" />}
      />
      <MenuList px="20px" bg="#252525" border="none">
        <Slider
          aria-label="slider volume"
          defaultValue={1}
          min={0}
          max={1}
          step={0.01}
          value={volume}
          onChange={e => handleChange(e.valueOf())}
        >
          <SliderTrack bg="rgba(255,255,255, 0.1)">
            <SliderFilledTrack bg="rgba(255,255,255, 0.2)" />
          </SliderTrack>
          <SliderThumb bg="#939393" _focus={{}} />
        </Slider>
      </MenuList>
    </Menu>
  );
}
