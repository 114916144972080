import { Box, Grid, useBreakpointValue } from '@chakra-ui/react';
import BackgroundSelectionDialog from 'components/BackgroundSelectionDialog/BackgroundSelectionDialog';
import ChatWindow from 'components/ChatWindow/ChatWindow';
import MainParticipant from 'components/MainParticipant/MainParticipant';
import ParticipantList from 'components/ParticipantList/ParticipantList';
import useChatContext from 'hooks/useChatContext/useChatContext';
import { useParticipantsGridMode } from 'state/ParticipantsGridMode';

export default function Room() {
  const { gridMode } = useParticipantsGridMode();
  const { isChatWindowOpen } = useChatContext();
  const isSmall = useBreakpointValue({ base: true, md: false });
  const mode2 = gridMode === 'mode 2';

  return (
    <Box h={{ base: 'calc(100vh - 90px)', xl: 'calc(100vh - 100px)' }} w="100%">
      <Grid
        w={isChatWindowOpen ? (isSmall ? '100%' : 'calc(100% - 430px)') : '100%'}
        transition="width .3s"
        h="100%"
        gridTemplateColumns={mode2 ? 'auto 380px' : '1fr'}
      >
        <MainParticipant />
        <ParticipantList />
      </Grid>
      <ChatWindow />
      <BackgroundSelectionDialog />
    </Box>
  );
}
