export const slimScroll = {
  '::-webkit-scrollbar': {
    width: `3px`,
    height: `3px`,
  },
  '::-webkit-scrollbar-track-piece': {
    borderRadius: `10px`,
    // boxShadow: `inset 1px 1px 2px #bebebe, inset -1px -1px 2px #ffffff`,
    background: `transparent`,
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: `10px`,
    backgroundColor: `#bbb`,
  },
  '::-webkit-scrollbar-corner': { display: `none` },
};
