import { Box, Flex, Text } from '@chakra-ui/react';
import useParticipantIsReconnecting from 'hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from 'hooks/usePublications/usePublications';
import useTrack from 'hooks/useTrack/useTrack';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import AvatarIcon from 'icons/AvatarIcon';
import React from 'react';
import { Participant } from 'twilio-video';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  // const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <Flex h="100%" w="100%" bg="#050505" justifyContent="center" alignItems="center">
      <Flex
        p="9px 9px 7px 9px"
        bg="rgba(0,0,0, 0.35)"
        zIndex={{ base: '10', xl: '1' }}
        pos="fixed"
        left="-1px"
        bottom={{ base: '90px', xl: '100px' }}
        borderRadius="0px 10px 0 0"
      >
        <Text fontSize="15" color="white" mr="10px">
          {participant.identity}
          {isLocal && ' (You)'}
          {screenSharePublication && ' - Screen'}
        </Text>
        <Box>
          <NetworkQualityLevel participant={participant} />
        </Box>
      </Flex>

      {!isVideoEnabled && (
        <Box>
          <AvatarIcon
            width={{ base: '80px', md: '15vw', xl: '8vw' }}
            height={{ base: '80px', md: '15vw', xl: '8vw' }}
          />
        </Box>
      )}

      {isParticipantReconnecting && (
        <Flex
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          alignItems="center"
          justifyContent="center"
          background="rgba(40, 42, 43, 0.75)"
          zIndex="1"
        >
          <Text color="#fff">Reconnecting...</Text>
        </Flex>
      )}
      {children}
    </Flex>
  );
}
