import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useAppState } from 'state';
import useRoleContext from 'hooks/useRoleContext/useRoleContext';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';

export default function EndCallButton({ ...rest }: ButtonProps) {
  const { room } = useVideoContext();
  const { updateRecordingRules } = useAppState();
  const { role } = useRoleContext();
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);

  const handleClick = async (): Promise<void> => {
    const query = new URLSearchParams(location.search);
    const userToken = query.get('userToken');
    if (role !== 'patient') {
      updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
      setLoading(true);
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/room/${URLRoomName}/${userToken}/close`);
        window.location.href = `${process.env.REACT_APP_FRONT_URL}/doctor/consults/after/${URLRoomName}`;
      } catch (error) {
        console.log(error);
      }
    } else {
      setLoading(true);
      window.location.href = `${process.env.REACT_APP_FRONT_URL}/patient/survey/${URLRoomName}`;
    }

    // room!.disconnect();
  };

  return (
    <>
      <Button
        px={{ base: '30px', lg: '34px' }}
        borderRadius="12"
        bg="#EA3535"
        fontSize={{ base: '14', lg: '16' }}
        _hover={{ bg: '#d01616' }}
        onClick={onOpen}
        data-cy-disconnect
        isLoading={loading}
        {...rest}
      >
        Finish Appointment
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={'15px'}>
          <ModalHeader>Finish Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={'center'} my="32px">
              Are you sure you want to finish this appointment? <br />
              <strong>You can not undo this action.</strong>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button borderRadius={'10px'} colorScheme={'red'} onClick={handleClick}>
              Finish Appointment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
