import { Icon, IconProps } from '@chakra-ui/react';

export function GridVideoMode1Icon({ active = true, ...rest }: IconProps & { active?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="#fff" {...rest}>
      <rect width="20" height="20" rx="3" opacity={active ? '1' : '0.5'} />
    </Icon>
  );
}

export function GridVideoMode2Icon({ active = true, ...rest }: IconProps & { active?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20.001" {...rest}>
      <g opacity={active ? '1' : '0.5'}>
        <path
          d="M14.168,9.167H12.5c-.07,0-.139,0-.208-.007s-.138.007-.208.007H3.333A3.332,3.332,0,0,1,0,5.835v-2.5A3.332,3.332,0,0,1,3.333,0h8.749c.07,0,.139,0,.208.006S12.428,0,12.5,0h4.168A3.335,3.335,0,0,1,20,3.335v2.5a3.332,3.332,0,0,1-3.333,3.332Z"
          transform="translate(0.001)"
          fill="#fff"
        />
        <path
          d="M3.333,277.333h2.5a3.333,3.333,0,0,1,3.333,3.333v2.5A3.333,3.333,0,0,1,5.833,286.5h-2.5A3.333,3.333,0,0,1,0,283.166v-2.5A3.333,3.333,0,0,1,3.333,277.333Z"
          transform="translate(0 -266.499)"
          fill="#fff"
        />
        <path
          d="M280.667,277.333h2.5a3.333,3.333,0,0,1,3.333,3.333v2.5a3.333,3.333,0,0,1-3.333,3.333h-2.5a3.333,3.333,0,0,1-3.333-3.333v-2.5A3.333,3.333,0,0,1,280.667,277.333Z"
          transform="translate(-266.501 -266.499)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}

export function GridVideoMode3Icon({ active = true, ...rest }: IconProps & { active?: boolean }) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" {...rest}>
      <g id="apps" opacity={active ? '1' : '0.5'}>
        <path
          d="M3.333,0h2.5A3.333,3.333,0,0,1,9.167,3.333v2.5A3.333,3.333,0,0,1,5.833,9.167h-2.5A3.333,3.333,0,0,1,0,5.833v-2.5A3.333,3.333,0,0,1,3.333,0Z"
          transform="translate(0 0)"
          fill="#fff"
        />
        <path
          d="M5.833,9.167h-2.5A3.338,3.338,0,0,1,0,5.833v-2.5A3.338,3.338,0,0,1,3.335,0h2.5A3.337,3.337,0,0,1,9.167,3.334v2.5A3.337,3.337,0,0,1,5.833,9.167ZM3.568.862A2.71,2.71,0,0,0,.861,3.568V5.6A2.71,2.71,0,0,0,3.568,8.306H5.6A2.71,2.71,0,0,0,8.306,5.6V3.568A2.71,2.71,0,0,0,5.6.862Z"
          transform="translate(10.833)"
          fill="#fff"
        />
        <path
          d="M3.333,277.333h2.5a3.333,3.333,0,0,1,3.333,3.333v2.5A3.333,3.333,0,0,1,5.833,286.5h-2.5A3.333,3.333,0,0,1,0,283.166v-2.5A3.333,3.333,0,0,1,3.333,277.333Z"
          transform="translate(0 -266.499)"
          fill="#fff"
        />
        <path
          d="M280.667,277.333h2.5a3.333,3.333,0,0,1,3.333,3.333v2.5a3.333,3.333,0,0,1-3.333,3.333h-2.5a3.333,3.333,0,0,1-3.333-3.333v-2.5A3.333,3.333,0,0,1,280.667,277.333Z"
          transform="translate(-266.501 -266.499)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
}
