import { Box, Flex, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import CloseIcon from 'icons/CloseIcon';
import { InfoOutlineIcon } from 'icons/InfoOutlineIcon';
import useChatContext from 'hooks/useChatContext/useChatContext';
import ChatInput from './ChatInput/ChatInput';
import MessageList from './MessageList/MessageList';

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
  const { isChatWindowOpen, setIsChatWindowOpen, messages, conversation } = useChatContext();
  const isSmall = useBreakpointValue({ base: true, md: false });
  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
  };

  const variants = {
    open: { x: '0' },
    closed: { x: '100%' },
  };

  return (
    <>
      <motion.div
        animate={isChatWindowOpen ? 'open' : 'closed'}
        style={{
          width: isSmall ? '100%' : '430px',
          background: '#fff',
          position: 'fixed',
          right: '0',
          top: isSmall ? '0' : '70px',
          bottom: isSmall ? '0' : '100px',
          zIndex: '1001',
          x: '100%',
          boxShadow: '0 0 20px rgba(0,0,0, 0.16)',
          borderRadius: '15px 0 0 15px',
        }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        variants={variants}
      >
        <Stack
          alignItems="start"
          flexDirection="column"
          h="100%"
          padding="18px 15px 15px 15px"
          justifyContent="space-between"
          spacing="0"
        >
          {/* drawer header */}
          <Flex p="0px 10px 10px 10px" w="100%" alignItems="center" justifyContent="space-between">
            <Box>
              <InfoOutlineIcon />
            </Box>
            <Text fontSize="18">Consult Chat</Text>
            <Flex
              aria-label="close chat"
              onClick={toggleChatWindow}
              children={<CloseIcon width="15px" height="15px" />}
              justifyContent="center"
              borderRadius="5"
              _hover={{ bg: 'rgba(0,0,0, 0.1)' }}
              transition="0.3s"
              alignItems="center"
              w="25px"
              h="25px"
              p="0"
            />
          </Flex>
          <MessageList messages={messages} />
          {/* drawer footer */}
          <ChatInput conversation={conversation!} isChatWindowOpen={isChatWindowOpen} />
        </Stack>
      </motion.div>
    </>
  );
}
