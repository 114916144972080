import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export function RecordingTimer({ isRecording }: { isRecording: boolean }) {
  const [timer, setTimer] = useState('0:00:00');
  const [startDate, setStartDate] = useState<null | Date>(null);

  function Timer(): string {
    if (startDate === null) {
      return '0' + ':' + '00' + ':' + '00';
    }
    const current = +new Date() - +startDate;
    let h: number | string = Math.floor((current / (1000 * 60 * 60)) % 24);
    let m: number | string = Math.floor((current / 1000 / 60) % 60);
    let s: number | string = Math.floor((current / 1000) % 60);
    m = checkTime(m);
    s = checkTime(s);
    return h + ':' + m + ':' + s;
  }

  function checkTime(i: number | string): number | string {
    if (i < 10) {
      i = '0' + i;
    } // add zero in front of numbers < 10
    return i;
  }

  useEffect(() => {
    if (isRecording) {
      const timerOut: ReturnType<typeof setTimeout> = setTimeout(() => {
        if (startDate === null) {
          setStartDate(new Date());
        }
        setTimer(Timer());
        return clearTimeout(timerOut);
      }, 1000);
    } else {
      setTimer('0:00:00');
    }
  }, [isRecording, timer, startDate]);

  return <Text display={{ base: 'none', md: 'block' }}>{timer}</Text>;
}
