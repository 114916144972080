import MenuBar from 'components/MenuBar/MenuBar';
import TopMenuBar from 'components/TopMenuBar/TopMenuBar';
import PreJoinScreens from 'components/PreJoinScreens/PreJoinScreens';
import Notifications from 'components/Notifications';
import Room from 'components/Room/Room';

import useRoomState from 'hooks/useRoomState/useRoomState';
import { Box } from '@chakra-ui/react';
import { GridModeProvider } from 'state/ParticipantsGridMode';
import { RoleProvider } from 'components/RoleProvider';

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.

  return (
    <RoleProvider>
      <Box w="100%" minH="100vh">
        {roomState === 'disconnected' ? (
          <PreJoinScreens />
        ) : (
          <Box overflow="hidden" paddingBottom="0" background="#1a1a1a" maxW="100vw">
            <Notifications />
            <GridModeProvider>
              <TopMenuBar />
              <Room />
            </GridModeProvider>
            <MenuBar />
          </Box>
        )}
      </Box>
    </RoleProvider>
  );
}
