import { Icon, IconProps } from '@chakra-ui/react';

export function VolumeIcon({ volume = 100, ...rest }: { volume: number } & IconProps) {
  return (
    <>
      {volume === 0 ? (
        <Icon
          xmlns="http://www.w3.org/2000/svg"
          width="22.434"
          height="16.842"
          viewBox="0 0 22.434 16.842"
          stroke="#fff"
          {...rest}
        >
          <g id="Icon_feather-volume-2" data-name="Icon feather-volume-2" transform="translate(-2 -5.979)">
            <path
              d="M12.544,7.5l-5.3,4.242H3V18.1H7.242l5.3,4.242Z"
              transform="translate(0 -0.521)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path d="M16.558,11.168l5.249,5.249,1.211,1.211" fill="none" stroke-linecap="round" stroke-width="2" />
            <path
              d="M16.558,18l6.463-6.463"
              transform="translate(-0.002 -0.368)"
              fill="none"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </Icon>
      ) : (
        <Icon
          xmlns="http://www.w3.org/2000/svg"
          width="23.208"
          height="17.824"
          viewBox="0 0 23.208 17.824"
          stroke="#fff"
          {...rest}
        >
          <g transform="translate(-2 -5.981)">
            <path
              d="M12.544,7.5l-5.3,4.242H3V18.1H7.242l5.3,4.242Z"
              transform="translate(0 -0.031)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              d="M27.054,7.4a10.6,10.6,0,0,1,0,15M23.31,11.139a5.3,5.3,0,0,1,0,7.5"
              transform="translate(-5.951 0)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </Icon>
      )}
    </>
  );
}
