import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import VideoInputList from './VideoInputList/VideoInputList';
import { ModalContentCustom } from 'components/ModalContentCustom';
import { Box, Flex, Text, Button as ChakraButton } from '@chakra-ui/react';
import LocalVideoPreview from 'components/PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';

export default function DeviceSelectionDialog({
  open,
  onClose,
  audioSettings = true,
  videoSettings = true,
}: {
  open: boolean;
  onClose: () => void;
  audioSettings?: boolean;
  videoSettings?: boolean;
}) {
  return (
    <ModalContentCustom isOpen={open} onClose={onClose} minW="33vw" maxW={{ base: '95vw', xl: '33vw' }}>
      <Box p={{ base: '20px 25px 25px 25px', md: '20px 40px 40px 40px' }} w="100%">
        <Text fontSize={{ base: '25', md: '30' }} fontWeight="bold" mb="10px">
          {audioSettings && videoSettings ? 'Audio & Video' : audioSettings ? 'Audio' : 'Video'}
        </Text>
        {audioSettings ? (
          <>
            {audioSettings && videoSettings ? (
              <Text mb="20px" fontSize={{ base: '20', md: '24' }} color="brand.500">
                Audio
              </Text>
            ) : null}
            <Text fontSize={{ base: '15', md: '18' }} mb="10px">
              Audio Input
            </Text>

            <AudioInputList />

            <Text fontSize={{ base: '15', md: '18' }} mt="20px" mb="10px">
              Audio Output
            </Text>

            <AudioOutputList />
          </>
        ) : null}

        {videoSettings ? (
          <>
            {audioSettings && videoSettings ? (
              <Text my="20px" fontSize={{ base: '20', md: '24' }} color="brand.500">
                Video
              </Text>
            ) : null}
            <Flex mb="20px" justifyContent="center">
              <Box borderRadius="10px" w="70%" overflow="hidden">
                <LocalVideoPreview />
              </Box>
            </Flex>
            <Text fontSize={{ base: '15', md: '18' }} mb="10px">
              Video Input
            </Text>
            <Text>
              <VideoInputList />
            </Text>
            <Flex mt="20px" justifyContent="flex-end">
              <ChakraButton size="custom" ml="auto" variant="branded" onClick={onClose}>
                Done
              </ChakraButton>
            </Flex>
          </>
        ) : null}
      </Box>
    </ModalContentCustom>
  );
}
