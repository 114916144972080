import { ChakraProvider } from '@chakra-ui/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import UnsupportedBrowserWarning from 'components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AppStateProvider, { useAppState } from 'state';
import theme from 'theme';
import App from './App';
import chakraTheme from './chakra/theme';
import { ChatProvider } from './components/ChatProvider';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { VideoProvider } from './components/VideoProvider';
import './types';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const userToken = urlParams.get('userToken');

  useEffect(() => {
    if (userToken) {
      window.localStorage.setItem('userToken', userToken);
    }
  }, [userToken]);

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ChatProvider>
        <App />
      </ChatProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <ChakraProvider theme={chakraTheme}>
    <MuiThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      {
        //@ts-ignore
        <UnsupportedBrowserWarning>
          {
            //@ts-ignore
            <Router>
              <AppStateProvider>
                <Switch>
                  <PrivateRoute exact path="/">
                    <VideoApp />
                  </PrivateRoute>
                  <PrivateRoute path="/room/:URLRoomName">
                    <VideoApp />
                  </PrivateRoute>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Redirect to="/login" />
                </Switch>
              </AppStateProvider>
            </Router>
          }
        </UnsupportedBrowserWarning>
      }
    </MuiThemeProvider>
  </ChakraProvider>,
  document.getElementById('root')
);
