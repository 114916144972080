import React from 'react';
import { Message } from '@twilio/conversations/lib/message';
import MessageInfo from './MessageInfo/MessageInfo';
import { MessageListScrollContainer } from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import MediaMessage from './MediaMessage/MediaMessage';
import { useState } from 'react';
import { useEffect } from 'react';

interface MessageListProps {
  messages: Message[];
}
interface MessageColors {
  author: string;
  color: string;
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

function chooseColorsForParticipants(messages: Message[], colors: MessageColors[] | undefined = undefined) {
  const colorRange = [118, 200];
  function getRandom(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
  }
  const participantsOfMessages: string[] = [...new Set(messages.map(message => message.author))];
  if (colors) {
    let copyOfColors = [...colors];
    for (let participantName of participantsOfMessages.reverse()) {
      const newParticipant = colors.find(color => color.author === participantName) === undefined;
      console.log(newParticipant);
      if (newParticipant) {
        copyOfColors.push({
          author: participantName,
          color: `rgb(${getRandom(colorRange[0], colorRange[1])},${getRandom(colorRange[0], colorRange[1])},${getRandom(
            colorRange[0],
            colorRange[1]
          )})`,
        });
        return copyOfColors;
      }
    }
    return copyOfColors;
  } else {
    let participantColors = [];
    for (let participantName of participantsOfMessages) {
      participantColors.push({
        author: participantName,
        color: `rgb(${getRandom(colorRange[0], colorRange[1])},${getRandom(colorRange[0], colorRange[1])},${getRandom(
          colorRange[0],
          colorRange[1]
        )})`,
      });
    }
    return participantColors;
  }
}

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [messageColors, setMessageColors] = useState<MessageColors[] | undefined>();

  // messages = messages.filter(message => message.author === localParticipant.identity || )

  useEffect(() => {
    if (messages.length > 0) {
      setMessageColors(chooseColorsForParticipants(messages, messageColors));
    }
  }, [messages]);

  return (
    <MessageListScrollContainer messages={messages}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);
        const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;
        const isLocalParticipant = localParticipant.identity === message.author;

        return (
          <React.Fragment key={message.sid}>
            {shouldDisplayMessageInfo && (
              <MessageInfo author={message.author} isLocalParticipant={isLocalParticipant} dateCreated={time} />
            )}
            {message.type === 'text' && (
              <TextMessage
                body={message.body}
                author={message.author}
                isLocalParticipant={isLocalParticipant}
                colors={messageColors}
                showLetter={shouldDisplayMessageInfo}
              />
            )}
            {message.type === 'media' && <MediaMessage media={message.media} />}
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
